import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { of } from 'rxjs';

import { MetricTableColumn, OptionListOption, ValueDefinitionType } from '../../../../../models';
import { UniquenessValidator } from '../../../../../validators';

export interface TableContextColumnDialogFormResult {
  label: string;
  options: OptionListOption[];
}

type TableContextColumnDialogFormModel = {
  label: FormControl<string>;
  options: FormControl<OptionListOption[]>;
};

export class TableContextColumnDialogForm extends FormGroup<TableContextColumnDialogFormModel> {
  public static delay = 0;

  constructor(
    existingColumns: MetricTableColumn[],
    metricTableColumnDefinition?: MetricTableColumn,
    maxRowCount = 200,
    fb: FormBuilder = new FormBuilder(),
  ) {
    const options = metricTableColumnDefinition?.context_options?.map((o) => ({ id: o.id, label: o.label }));
    super(
      {
        label: fb.control(metricTableColumnDefinition?.label ?? '', {
          nonNullable: true,
          validators: Validators.required,
          asyncValidators: UniquenessValidator.validate(
            (value) => of(!existingColumns.some((col) => value.toLowerCase() === col.label.toLowerCase())),
            [],
            TableContextColumnDialogForm.delay,
          ),
        }),
        options: fb.control(options ?? [], {
          nonNullable: true,
          validators: Validators.required,
        }),
      },
      { validators: TableContextColumnDialogForm.validateMaxRowCount(existingColumns, maxRowCount) },
    );
  }

  public updateOptions(options: OptionListOption[], markAsDirty = true): void {
    this.controls.options.setValue(options);
    if (markAsDirty) {
      this.markAsDirty();
    }
  }

  public toModel(): TableContextColumnDialogFormResult {
    return { label: this.controls.label.value, options: this.controls.options.value };
  }

  public static validateMaxRowCount(existingColumns: MetricTableColumn[], maxRowCount: number): ValidatorFn {
    return (control: AbstractControl<{ options: string[] }>): ValidationErrors | null => {
      const contextColumns = existingColumns.filter((c) => c.type === ValueDefinitionType.label);
      const totalCount =
        contextColumns.reduce((acc, col) => acc * (col.context_options || col.options).length, 1) *
        control.value.options.length;

      if (totalCount > maxRowCount) {
        return { maxRowCount: true };
      }

      return null;
    };
  }
}
