import {
  NumberTypeDetails,
  LabelTypeDetails,
  TypeDetails,
  BooleanTypeDetails,
  TipTypeDetails,
  SubtitleTypeDetails,
  DocumentTypeDetails,
  ChoiceTypeDetails,
} from './type_details';
import { OptionListOption, ValueDefinitionSize } from './ui';
import { Value, ValueBreakdownFrequency } from './values';
import {
  CreatedUpdatedEntity,
  Doc,
  Entity,
  Framework,
  Industry,
  MetricCategory,
  StandardCodes,
  Tag,
  Topic,
} from './entities';
import { DataRequestValueGroupSetStatus } from './data-requests';
import {
  ConsolidationRules,
  ConsolidationTriggers,
  ListIndicatorsParams,
  MetricTaxonomies,
  Taxonomy,
  ValueDefinitionFrequency,
} from './';
import { MetricTableGroup, ValueDefinitionGroupOrMetricTableGroup } from '../metric-structure/models';

export enum RelatedMetricSource {
  core = 'core',
  platform = 'platform',
  shared = 'shared',
}

export interface Indicator extends Metric {
  framework?: Framework;
  framework_code?: string;
  value_group_sets?: ValueGroupSet[];
  instructions?: string;
  end?: string;
  position?: number;
  standard_codes?: StandardCodes[];
  related_metrics?: IndicatorRelatedMetrics;
  topicsString?: string;
}

export interface RelatedMetric {
  equivalent_metric: Indicator;
  metric_table_definition_ids: string[];
  original_metric_ids: string[];
  value_definition_ids: string[];
  source?: RelatedMetricSource;
}

export type IndicatorRelatedMetrics = RelatedMetric[];

export type MinimalIndicator = Pick<Metric, 'id' | 'code' | 'description'> & { metric_id: string };
export type MinimalMetric = Pick<Metric, 'id' | 'code' | 'description'>;

export interface Metric extends Entity {
  active: boolean;
  category: MetricCategory;
  start_year: number;
  description: string;
  core_metric_id?: string;
  end_year?: number;
  framework?: Framework;
  framework_code?: string;
  framework_id?: string;
  guidance?: string;
  disclaimers?: string;
  imported?: boolean;
  indicator_id?: string;
  industries?: Industry[];
  metric_id?: string; // TODO: to be removed if API returns id instead of metric_id
  taxonomies?: MetricTaxonomies;
  tags?: Tag[];
  standard_codes?: StandardCodes[];
  // industry_ids: string[];
  // tags?: string[];
  topics?: Topic[];
  type?: 'rfp' | 'third_party';
  value_definition_groups?: ValueDefinitionGroup[];
  related_metrics?: IndicatorRelatedMetrics;
  start?: string;
  reference_v2?: boolean;
}

export interface MetricCreateRequest {
  code?: string;
  framework_id?: string;
  description?: string;
  category?: MetricCategory;
  guidance?: string;
  disclaimers?: string;
  start?: string;
  end?: string;
  tags?: string[];
  topics?: string[];
  industries?: string[];
  standard_codes?: string[];
  metric_equivalents?: string[];
  reference_v2?: boolean;
}

export interface FieldInformationRequest {
  technical_protocol?: string;
}

export interface Validator extends CreatedUpdatedEntity {
  value_definition_id?: string;
  validator_type: string; // 'min_length' | 'max_length' | 'min_val' | 'max_val' | 'min_date_range' | 'max_date_range'
  value?: string;
  instructions?: string | any[] | object;
  id?: string;
}

export enum ValueDefinitionType {
  text = 'text',
  label = 'label',
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  datetime = 'datetime',
  choice = 'choice',
  tip = 'tip',
  subtitle = 'subtitle',
  calculated = 'calculated',
  file = 'file',
  file_v2 = 'file_v2',
  document = 'document',
  metric = 'metric',
}

export enum ValueDefinitionFieldType {
  number = 'number',
  calculated = 'calculated',
  boolean = 'boolean',
  choice = 'choice',
  table = 'table',
  table_total = 'table_total',
  text = 'text',
  textarea = 'textarea',
  rich_text = 'rich_text',
  date = 'date',
  file = 'file',
  file_v2 = 'file_v2',
  repeatable_group = 'repeatable_group',
  tip = 'tip',
  subtitle = 'subtitle',
  document = 'document',
  metric = 'metric',
}

export const READONLY_VALUE_DEFINITION_TYPES = [
  ValueDefinitionType.tip,
  ValueDefinitionType.calculated,
  ValueDefinitionType.subtitle,
  ValueDefinitionType.label,
  ValueDefinitionType.document,
];

export const FILE_TYPES = [ValueDefinitionType.file, ValueDefinitionType.file_v2];
export const READONLY_HELPER_TEXTS_VALUE_DEFINITION_TYPES = [
  ValueDefinitionType.tip,
  ValueDefinitionType.label,
  ValueDefinitionType.subtitle,
];

export const NUMERIC_FIELD_TYPES = [ValueDefinitionType.number];

export const METRIC_TABLE_CONTEXT_COLUMN_TYPES = [ValueDefinitionType.label];

export const isContextColumn = (vd: ValueDefinition): vd is ValueDefinition<LabelTypeDetails> =>
  METRIC_TABLE_CONTEXT_COLUMN_TYPES.includes(vd.type);

export const CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES: ValueDefinitionType[] = [
  ValueDefinitionType.boolean,
  ValueDefinitionType.choice,
  ValueDefinitionType.date,
  ValueDefinitionType.datetime,
  ValueDefinitionType.number,
  ValueDefinitionType.file,
  ValueDefinitionType.label,
  ValueDefinitionType.metric,
  ValueDefinitionType.text,
];

export const CONDITIONAL_TRIGGER_SOURCE_TYPES = [
  ValueDefinitionType.boolean,
  ValueDefinitionType.choice,
  ValueDefinitionType.number,
];

export enum PlatformValueGroupSetStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
}

export interface ValueDefinition<T extends TypeDetails = any> extends Entity {
  type: ValueDefinitionType;
  type_details?: T;
  position: number;
  label?: string;
  short_label?: string;
  choices?: string;
  size: ValueDefinitionSize;
  newline: boolean;
  required: boolean;
  value_definition_group_id: string;
  consolidation_rule: ConsolidationRules;
  consolidation_trigger?: ConsolidationTriggers | null;
  bypass_consolidation_levels?: string[] | null;
  unit?: string;
  core_value_definition_id?: string;
  validators?: Validator[];
  hint?: string;
  document?: Doc;
  conditional_triggers?: ConditionalTrigger[];
  context: boolean;
  standard_codes?: StandardCodes[];
  metric_table_calculation_field_definition_id?: string;
  active?: boolean;
  end_fiscal_year_frequency_code?: string;
  published?: string;
  published_by?: string;
  technical_protocol?: string;
  value_definition_group?: ValueDefinitionGroup;
  taxonomies?: Taxonomy[];
  value_definition_frequency: ValueDefinitionFrequency | null;
  value_breakdown_frequency: ValueBreakdownFrequency[];
}

export function isValueDefinition(value: unknown): value is ValueDefinition {
  return value != null && (value as ValueDefinition).value_definition_group_id != null;
}

export const isVdNumberType = (vd: ValueDefinition): vd is ValueDefinition<NumberTypeDetails> =>
  NUMERIC_FIELD_TYPES.includes(vd.type);

export const isVdLabelType = (vd: ValueDefinition): vd is ValueDefinition<LabelTypeDetails> =>
  vd.type === ValueDefinitionType.label;

export const isVdBooleanType = (vd: ValueDefinition): vd is ValueDefinition<BooleanTypeDetails> =>
  vd.type === ValueDefinitionType.boolean;

export const isVdChoiceType = (vd: ValueDefinition): vd is ValueDefinition<ChoiceTypeDetails> =>
  vd.type === ValueDefinitionType.choice;

export const isVdTipType = (vd: ValueDefinition): vd is ValueDefinition<TipTypeDetails> =>
  vd.type === ValueDefinitionType.tip;

export const isVdSubtitleType = (vd: ValueDefinition): vd is ValueDefinition<SubtitleTypeDetails> =>
  vd.type === ValueDefinitionType.subtitle;

export const isVdDocumentType = (vd: ValueDefinition): vd is ValueDefinition<DocumentTypeDetails> =>
  vd.type === ValueDefinitionType.document;

export type ValueDefinitionTemplate<T extends TypeDetails = any> = Omit<ValueDefinition<T>, 'position'> & {
  disabled?: boolean;
};

export interface ValueDefinitionGroup extends Entity {
  position: number;
  repeatable: boolean;
  required?: boolean;
  label?: string;
  group_original_presentation?: string;
  group_max_repetition?: number;
  show_label_as_subtitle?: boolean;
  indent: number;
  metric_id: string;
  core_value_definition_group_id?: string | null;
  value_definitions?: ValueDefinition[];
  conditional_triggers?: ConditionalTrigger[];
  table_id?: string | null;
  is_calculation?: boolean;
  active?: boolean;
  end_fiscal_year_frequency_code?: string;
  metric_table_definition?: MetricTableDefinition;
}

export function isValueDefinitionGroup(value: unknown): value is ValueDefinitionGroup {
  return value != null && (value as ValueDefinitionGroup).metric_id != null;
}

export type ValueDefinitionGroupTemplate = Omit<ValueDefinitionGroup, 'position'>;

export type MetricEditorGroup = ValueGroup | TableGroup;

export interface ValueGroup extends Entity {
  subposition?: number | null;
  position: number;
  repeatable?: boolean;
  required?: boolean;
  indicator_id: string;
  indent: number;
  label?: string;
  group_original_presentation: string;
  group_max_repetition: number;
  show_label_as_subtitle?: boolean;
  value_definition_group_id: string;
  values?: Value[];
  conditional_triggers?: ConditionalTrigger[];
  table_id?: string | null;
  metric_id?: string;
}

export interface TableGroup {
  id: string;
  table_id: string;
  indicator_id: string;
  position: number;
  valueGroups: ValueGroup[];
}

export function isTableGroup(value: any): value is TableGroup {
  return 'table_id' in value && 'valueGroups' in value;
}

export function isMetricTableGroup(value: ValueDefinitionGroupOrMetricTableGroup): value is MetricTableGroup {
  return Boolean(value.table_id);
}

export interface ValueGroupSet extends Entity {
  indicator_id: string;
  start?: string;
  end?: string;
  business_unit_id: string;
  business_unit_level?: number;
  explanation?: string;
  consolidated: boolean;
  target_value?: string;
  target_description?: string;
  target_note?: string;
  target_status?: 'on_schedule' | 'behind_schedule' | 'target_met';
  value_groups?: ValueGroup[];
  nb_values_required?: number;
  nb_values_required_completed?: number;
  frequency_code?: string;
  org_id?: string;
  data_request_id?: string;
  status: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  status_context?: VGSetStatusContext;
}

export interface ConditionalTriggerTarget {
  id: string;
  conditional_trigger_id: string;
  value_definition_id?: string;
  value_definition_group_id?: string;
}

export interface ConditionalTrigger {
  id: string;
  source_value_definition_id: string;
  values: string[] | boolean[] | string;
  targets: ConditionalTriggerTarget[];
  operator?: string;
}

export type ConditionalTriggerTargetUpsert = Pick<
  ConditionalTriggerTarget,
  'value_definition_id' | 'value_definition_group_id'
>;

export type ConditionalTriggerUpsertPayload = Pick<ConditionalTrigger, 'values' | 'operator'> & {
  targets: ConditionalTriggerTargetUpsert[];
};

export interface MetricTableColumnDefinition {
  id: string;
  metric_table_definition_id: string;
  label: string;
  position: number;
  type: ValueDefinitionType;
  type_details: TypeDetails;
  validators: Validator[];
  options: string[];
  context_options: MetricTableColumnOption[];
  consolidation_rule?: ConsolidationRules;
  consolidation_trigger?: ConsolidationTriggers | null;
  bypass_consolidation_levels?: number[] | null;
}

export interface MetricTableInputDefinition extends MetricTableColumnDefinition {
  maximum?: number;
  minimum?: number;
  inputType?: string;
}
export enum MetricTableCalculationTypeDetailFormulaOperation {
  SUM = 'sum',
  AVERAGE = 'avg',
}

export interface MetricTableCalculationTypeDetailFormulaFilter {
  column: string;
  values: string[];
}

export interface MetricTableCalculationTypeDetailFormula {
  operation: MetricTableCalculationTypeDetailFormulaOperation;
  columns: string[];
  filters: MetricTableCalculationTypeDetailFormulaFilter[];
}

export interface MetricTableCalculationTypeDetail {
  formula: MetricTableCalculationTypeDetailFormula;
  units: string;
  family: string;
  max_decimals: number | string;
}

export interface MetricTableCalculationDefinition {
  id?: string;
  metric_table_definition_id?: string;
  label: string;
  position: number;
  type_details: MetricTableCalculationTypeDetail;
}

export interface CreateMetricTableColumnDefinitionPayload {
  label: string;
  type: ValueDefinitionType;
  consolidation_rule?: ConsolidationRules;
  consolidation_trigger?: ConsolidationTriggers | null;
  options?: string[];
  type_details?: TypeDetails;
  validators?: Validator[];
}

export interface UpdateMetricTableColumnDefinitionPayload {
  label: string;
  consolidation_rule?: ConsolidationRules;
  consolidation_trigger?: ConsolidationTriggers | null;
  options?: OptionListOption[];
  type_details?: TypeDetails;
  validators?: Validator[];
  reset_consolidated_values?: boolean;
}

export interface MetricTableColumnOption {
  id: string;
  column_id: string;
  label: string;
  position: number;
}

export interface MetricTableDefinition {
  id: string;
  title: string;
  metric_id: string;
  column_definitions: MetricTableColumnDefinition[];
  calculation_definitions: MetricTableCalculationDefinition[];
  active?: boolean;
  end_fiscal_year_frequency_code?: string;
  has_values?: boolean;
  technical_protocol?: string;
  taxonomies?: Taxonomy[];
  core_metric_table_definition_id?: string;
}

export type MetricTableColumnDefinitionUpsert = Omit<
  MetricTableColumnDefinition,
  'id' | 'metric_table_definition_id' | 'context_options'
>;
export type MetricTableCalculationDefinitionUpsert = Omit<
  MetricTableCalculationDefinition,
  'id' | 'metric_table_definition_id'
>;

export interface MetricTableDefinitionUpsertPayload {
  title: string;
  column_definitions: MetricTableColumnDefinitionUpsert[];
}

export interface MetricTableDefinitionUpdatePayload {
  title: string;
}

export enum VGSetStatusContext {
  entered_via_collect = 'entered_via_collect',
  completed_via_collect = 'completed_via_collect',
  imported_via_request = 'imported_via_request',
  request_values_overridden = 'request_values_overridden',
  imported_via_survey = 'imported_via_survey',
  imported_from_green_project = 'imported_from_green_project',
  some_values_entered_via_green_project_api = 'some_values_entered_via_green_project_api',
  reopened_from_consolidation = 'reopened_from_consolidation',
  user_action = 'user_action',
  data_entered_by_user = 'data_entered_by_user',
  new_calculated_field_added = 'new_calculated_field_added',
  new_source_added = 'new_source_added',
  calculated_field_modified = 'calculated_field_modified',
  consolidation_configuration_rules_modified = 'consolidation_configuration_rules_modified',
  approved_in_request_and_imported = 'approved_in_request_and_imported',
  consolidated_value_modified = 'consolidated_value_modified',
  approved_consolidated_value_no_longer_applicable = 'approved_consolidated_value_no_longer_applicable',
  imported_from_minimum = 'imported_from_minimum',
  some_values_entered_via_minimum_api = 'some_values_entered_via_minimum_api',
}

export enum IndicatorType {
  collect = 'collect',
  data_request = 'data_request',
}

export interface ListValueDefinitionMappingsPayload {
  page: number;
  page_size: number;
}

export interface CreateUpdateValueDefinitionMappingPayload {
  from_value_definition_id: string;
  note: string;
}

export interface MetricDataAcrossAxisItem extends Record<string, string | boolean> {
  name: string;
  field: string;
  html: boolean;
  type: ValueDefinitionType;
}

export interface MetricDataAcrossAxis {
  data: MetricDataAcrossAxisItem[];
  loading: boolean;
  columns: string[];
  title?: string;
  subtitle?: string;
}

export interface MetricLoaderFlags {
  load_value_definition_groups?: boolean;
  load_value_definitions?: boolean;
  load_related_equivalent?: boolean;
  load_related_core_equivalent?: boolean;
  load_conditional_triggers?: boolean;
  load_value_definition_standard_codes?: boolean;
  load_standard_codes?: boolean;
  load_tags?: boolean;
  load_topics?: boolean;
  load_validators?: boolean;
  load_framework?: boolean;
  load_industries?: boolean;
  load_leaf_taxonomies?: boolean;
  show_deactivated?: boolean;
  frequency_code?: string;
}

export enum MetricTableValueSelectionChoice {
  tableCell = 'table_cell',
  tableColumn = 'table_column',
}

export type MetricTableColumn = MetricTableColumnDefinitionUpsert & {
  id?: string;
  context_options?: MetricTableColumnOption[];
};

export interface GetIndicatorPayload extends ListIndicatorsParams {
  fiscal_year?: string | number;
  business_unit_id?: string;
  section_id?: string;
  report_id?: string;
}

export interface IndicatorAction {
  indicator_id: string;
  action_id: string;
}
