<mat-menu #groupMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [disabled]="moveUpDisabled" (click)="moveUp()">
      <mat-icon svgIcon="arrow-full-up" class="icon-sm"> </mat-icon>
      <span>{{ "Move up" | translate }}</span>
    </button>
    <button mat-menu-item [disabled]="moveDownDisabled" (click)="moveDown()">
      <mat-icon svgIcon="arrow-full-down" class="icon-sm"></mat-icon>
      <span>{{ "Move down" | translate }}</span>
    </button>
    <hr />
    <button mat-menu-item (click)="delete()">
      <mat-icon svgIcon="trash" class="icon-sm"></mat-icon>
      <span>{{ "Delete" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
<ng-container *ngIf="displayedValues?.length">
  <ng-container *ngIf="valueGroupFormGroup.valueGroupRef | displayValueGroupLabel">
    <h4 class="mat-subtitle-2 mb-3">{{ valueGroupFormGroup.valueGroupRef.label }}</h4>
  </ng-container>
  <a role="button" aria-label="options" *ngIf="showOptionMenu" class="options" [matMenuTriggerFor]="groupMenu">
    <mat-icon svgIcon="more"></mat-icon>
  </a>
  <div class="form">
    <ng-container *ngFor="let valueFormControl of displayedValues; trackBy: valueFormControlTrackBy">
      <lib-metric-editor-field-handler
        *ngIf="!(valueFormControl | isCalculatedFieldInRepeatableGroup: valueGroupFormGroup)"
        data-testid="field-handler"
        [class]="'fx-col justify-end ' + (valueFormControl.valueRef | valueDefinitionFieldSize)"
        [ngClass]="{ newline: valueFormControl.valueRef.newline }"
        [valueFormControl]="valueFormControl"
        [valueGroupFormGroup]="valueGroupFormGroup"
        [documentContext]="documentContext"
        [focusId]="valueGroupFormGroup.valueGroupRef | formatFieldUniqueId: valueFormControl.valueRef"
        [indicatorId]="indicatorId"
        [indicator]="indicator"
        [vgsetId]="vgsetId"
        [valueGroup]="valueGroupFormGroup.valueGroupRef"
        [displayFieldActions]="displayFieldActions"
        [collaboratorResponsibility]="collaboratorResponsibility"
        [valueGroupSetStatus]="valueGroupSetStatus"
        [dataRequestSourceStatus]="dataRequestSourceStatus"
        [sourceConfiguration]="sourceConfiguration"
        [integrationType]="integrationType"
        [disableFrequencyFields]="disableFrequencyFields"
        (metricLinkEdit)="metricLinkEdit.emit($event)"
        (update)="onUpdateValue($event)"
        (resetValue)="handleResetValue($event)"
      ></lib-metric-editor-field-handler>
    </ng-container>
  </div>
  <lib-divider class="w-100 mb-2 mt-4" *ngIf="showOptionMenu"> </lib-divider>
  <a
    *ngIf="valueGroupFormGroup | isShowAddGroupButton: valueGroupFormGroup.disabled : repeatableGroupCount"
    class="fx-row justify-start align-center mb-4 mt-2 btn btn-primary"
    (click)="repeatGroup()"
  >
    <mat-icon svgIcon="add" class="mr-1"></mat-icon><span>{{ "Add group" | translate }}</span>
  </a>
</ng-container>
