import { CreatedUpdatedEntity, Entity, Framework, Section, SectionTemplate, Tag, Topic } from '../entities';
import { PaginationFilter } from '../pagination';
import { PlatformValueGroupSetStatus } from '../metrics';
import { VGSetStatusContext } from '../metrics';

export const CategoryMetricMap = {
  questionnaire: 'metric',
  template_framework: 'standard_code',
};

export enum TemplateTypeLabel {
  questionnaire = 'Questionnaires',
  template_framework = 'Framework Template',
}

export enum ReportCategoryType {
  questionnaire = 'questionnaire',
  data_table = 'data_table',
  detailed = 'detailed',
  template_framework = 'template_framework',
}

export enum CategoryRouteMap {
  questionnaire = 'questionnaires',
  data_table = 'data-tables',
  detailed = 'custom-templates',
  template_framework = 'framework-templates',
}

export enum ReportStatusEnum {
  draft = 'draft',
  completed = 'completed',
  archived = 'archived',
}

export interface ReportFilteringOptions {
  search_term?: string;
  offset?: number;
  limit?: number;
}

export interface Report extends Entity {
  year: number;
  start: string;
  end: string;
  status: ReportStatusEnum;
  template_report_id?: string;
  unit_id: string;
  sections?: Section[];
  category: ReportCategoryType;
  author?: string;
  tags?: Tag[];
  frameworks?: Framework[];
  topics?: Topic[];
  description?: string;
  business_unit_id?: string;
  value_frequencies?: string[];
  report_frequencies?: string[];
  show_subtitles_in_export: boolean;
  show_instructions_in_export: boolean;
  show_unselected_choice_field_options: boolean;
  template_report_version_id?: string;
  prefill_values: boolean;
  recommendations_frequency_code?: string;
  template_report_version_name?: string;
  integration?: ReportIntegrationType;
  display_table_fields_as_a_list: boolean;
}

export interface ReportStatusTransition extends CreatedUpdatedEntity {
  id: string;
  report_id: string;
  to_status: ReportStatusEnum;
  from_status?: ReportStatusEnum;
  comment?: string;
}

export interface ValueGroupSetStatusTransition extends CreatedUpdatedEntity {
  id: string;
  value_group_set_id: string;
  from_status: PlatformValueGroupSetStatus;
  to_status: PlatformValueGroupSetStatus;
  from_status_context?: VGSetStatusContext;
  to_status_context?: VGSetStatusContext;
  sign_off_message?: string;
}

export interface ReportTemplate extends Entity {
  category: ReportCategoryType;
  report_type?: any;
  start: string;
  end: string;
  sections?: SectionTemplate[];
  frameworks: Framework[];
}

export interface ReportTemplateFilteringOptions extends PaginationFilter {
  filters: {
    search_term?: string;
    category?: ReportCategoryType | ReportCategoryType[];
    status?: ReportStatusEnum;
    business_units?: string[];
    template_reports?: string[];
    report_frequencies?: string[];
    frameworks?: string[];
    industries?: string[];
    tags?: Tag[];
    topics?: Topic[];
  };
}

export const copyableReportTypes = [ReportCategoryType.data_table, ReportCategoryType.detailed];
export const acceptedDocumentReportTypes = [ReportCategoryType.questionnaire, ReportCategoryType.detailed];

export interface TemplateCreateRequest
  extends Pick<
    Report,
    | 'name'
    | 'category'
    | 'report_frequencies'
    | 'value_frequencies'
    | 'start'
    | 'end'
    | 'recommendations_frequency_code'
    | 'template_report_version_name'
  > {
  business_unit_id: string | null;
  prefill_values?: boolean;
}

export interface TemplateGetRequestPayload {
  load_sections?: boolean;
  load_indicators?: boolean;
  load_value_group_sets?: boolean;
  load_value_groups?: boolean;
  load_values?: boolean;
  load_frameworks?: boolean;
  load_industries?: boolean;
  load_tags?: boolean;
  load_topics?: boolean;
}

export interface TemplateNameCheckRequest {
  name: string;
  category: ReportCategoryType;
}

export interface TemplateCreationCheckRequest {
  core_template_id: string;
  core_template_version_id: string;
  business_unit_id: string | null;
}

export interface TemplateCrationCheck {
  available: boolean;
}

export interface ReportAttachedCheck {
  attached: boolean;
}

export interface TemplateCompletionByStatus {
  [PlatformValueGroupSetStatus.NOT_STARTED]: number;
  [PlatformValueGroupSetStatus.IN_PROGRESS]: number;
  [PlatformValueGroupSetStatus.COMPLETE]: number;
}

export enum ReportIntegrationType {
  S_AND_P = 's_and_p',
  CDP = 'cdp',
}

export interface TemplateReportHierarchySummary {
  section_id: string;
  indicators_ids: string[];
}
