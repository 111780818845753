<ng-container *ngIf="form">
  <lib-slide-toggle
    *ngIf="form.controls.optionListItemId.value"
    class="formfield mb-3"
    [control]="form.controls.active"
    [label]="'Active' | translate"
  ></lib-slide-toggle>

  <lib-text-input
    class="formfield"
    [control]="form.controls.name"
    [label]="'Name' | translate"
    [messages]="{ isUnique: 'Name must be unique.' | translate }"
  ></lib-text-input>

  <ng-container *ngIf="withTagFields">
    <lib-text-input
      class="formfield"
      [control]="form.controls.spDataDpdValue"
      [label]="'API Tag - S&P' | translate"
    ></lib-text-input>

    <lib-text-input
      class="formfield"
      [control]="form.controls.cdpOptionId"
      [label]="'API Tag - CDP' | translate"
      [messages]="{ isNumeric: 'Must contain numbers only.' | translate }"
    ></lib-text-input>
  </ng-container>

  <lib-slide-toggle
    class="formfield mb-3"
    [control]="form.controls.displayExplanation"
    [label]="'Show explanation field' | translate"
  ></lib-slide-toggle>

  <lib-slide-toggle
    class="formfield"
    [control]="form.controls.explanationRequired"
    [label]="'Set explanation field to required' | translate"
  ></lib-slide-toggle>
</ng-container>
