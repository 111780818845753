import { AddOnConfig, Platforms, SupportedEnvironment } from '../app/shared/models';
import info from '../assets/info.json';
import { sharedApiConfig } from './shared_environment_config';

export const environment: AddOnConfig = {
  production: false,
  version: info.version,
  date: info.date,
  pageSize: 24,
  platform: Platforms.ADD_IN,
  apiConfig: {
    ...sharedApiConfig,
    env: SupportedEnvironment.staging,
    clientId: '112713f2-f9e8-45d7-a4e3-dafc8cb338c3',
  },
};
