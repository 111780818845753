import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricCategory } from '../../../models';
import { MetricTableGroup } from '../../models';

@Pipe({
  name: 'canDeleteMetricTable',
})
export class CanDeleteMetricTablePipe implements PipeTransform {
  transform(metricTableGroup: MetricTableGroup, metric: Metric, isCore?: boolean): boolean {
    if (isCore) {
      return false;
    }

    if (metric.category === MetricCategory.REFERENCE && metric.reference_v2) {
      return metricTableGroup.valueDefinitionGroups[0].core_value_definition_group_id === null;
    }

    return true;
  }
}
