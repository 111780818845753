import { Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MatomoTracker } from 'ngx-matomo-client';

import {
  ApiResponse,
  ApplicationApiDefinition,
  AuthMethod,
  Token,
  TwoFactorEnableRequest,
  TwoFactorInit,
  User,
  UserRoles,
  AdminUserRoles,
  BasicAuthPasskeyRegistrationOptions,
  BasicAuthPasskeyAuthOptions,
  BasicAuthPasskey,
  ClientLoginInfo,
  Permission,
  LocalStorageKey,
} from '../../../models';
import { ApiService } from '../api/api.service';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors/error-interceptor/bypass-error-constant';
import { CacheService } from '../cache/cache.service';
import { CookieService } from '../cookie/cookie.service';
import { HttpContext, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { LocalStorageUtils, ObservableUtils } from '../../../classes';
import { TokensService } from '../tokens/tokens.service';
import { TranslateService } from '../translate/translate.service';
import { AuthenticationResponseJSON, RegistrationResponseJSON } from '@simplewebauthn/types';
import { EvaluatedFeatureFlag } from '../../../feature-flag';
import { AuthApiService } from '../../api-services/auth-api-service/auth-api.service';

const MATOMO_USER_TYPE_DIMENSION_ID = 1;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthApiService {
  private _authMethod: AuthMethod = AuthMethod.basic;
  private _user?: User;
  private userSubject: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
  private readonly apiName: keyof ApplicationApiDefinition = 'auth';
  private readonly resource: string;
  private readonly dataResource: string;
  private readonly featureFlagResource: string;
  private readonly servicePath: string;
  private isRefreshingToken: boolean = false;
  private refreshTokenSubject: BehaviorSubject<Token | null> = new BehaviorSubject<Token | null>(null);
  constructor(
    private apiService: ApiService,
    private router: Router,
    private tokensService: TokensService,
    private cacheService: CacheService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    @Optional() private matomoTracker?: MatomoTracker,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.auth.resources.auth_basic;
    this.dataResource = this.apiService.apiConfig.apis.auth.resources.data;
    this.featureFlagResource = this.apiService.apiConfig.apis.auth.resources.featureFlags;
    this.userSubject.next(this.user);
    LocalStorageUtils.watchKeyChange<User>('user').subscribe((user: User | undefined) => {
      if (user) {
        this._user = user;
        this.userSubject.next(this._user);

        if (this.matomoTracker) {
          const userType = user.email.split('@')[1] === 'novisto.com' ? 'employee' : 'external';
          this.matomoTracker.setCustomDimension(MATOMO_USER_TYPE_DIMENSION_ID, userType);
        }
      } else {
        this.logout();
      }
    });
  }

  public get authMethod(): AuthMethod {
    return this._authMethod;
  }

  public get isAdmin(): boolean {
    return Boolean(this._user?.roles.includes(UserRoles.admin));
  }

  public get isViewer(): boolean {
    return Boolean(this._user?.roles.includes(UserRoles.viewer));
  }

  public get requireSourceOnDocuments(): boolean {
    return !!this._user?.require_source_on_documents;
  }

  public get user(): User | undefined {
    if (!this._user) {
      this._user = LocalStorageUtils.getFromStorage<User>('user');
    }
    return this._user;
  }

  public get tokenExpired(): string | undefined {
    // one hour prior to refresh token expiration
    if (this._user && this._user.rexp < Math.floor(Date.now() / 1000) + 3600) {
      return 'refresh_token';
    }
    // Using the line below instead of the line above will expire the session in 1 minute (used for testing)
    // if (this.user && this.user.rexp < Math.floor(Date.now() / 1000) + 23*3600 + 3590) return 'refresh_token';
    // 5 min prior to access token expiration
    if (this.user && this.user.exp < Math.floor(Date.now() / 1000) + 300) {
      return 'access_token';
    }
    return undefined;
  }

  public get accessTokenExpired(): boolean | undefined {
    if (!this.user) {
      return undefined;
    } else {
      return this.user.exp < Math.floor(Date.now() / 1000);
    }
  }

  public getUserPermissions(): Observable<Permission[] | null> {
    return this.getUserInfo().pipe(map((user) => user?.permissions || null));
  }

  getUserInfo(): Observable<User | undefined> {
    return this.userSubject.asObservable();
  }

  getUserRoles(): UserRoles[] {
    return Object.values(UserRoles);
  }

  getAdminUserRoles(): AdminUserRoles[] {
    return Object.values(AdminUserRoles);
  }

  saveUser(token: Token): boolean {
    if (!token) {
      throw new Error('Token not found');
    }

    const tokenParts = token.access_token.split('.');
    if (tokenParts.length < 2) {
      throw new Error('Invalid token');
    }

    this._user = JSON.parse(decodeURIComponent(escape(atob(tokenParts[1]))));
    if (!this._user) {
      throw new Error('Cannot parse user from token');
    }

    const refreshTokenParts = token.refresh_token.split('.');
    if (refreshTokenParts.length < 2) {
      throw new Error('Refresh token not found');
    }

    const rToken = JSON.parse(decodeURIComponent(escape(atob(refreshTokenParts[1]))));
    if (!rToken) {
      throw new Error('Invalid refresh token');
    }

    this._user.access_token = token.access_token;
    this._user.refresh_token = token.refresh_token;
    this._user.rexp = rToken.exp;
    LocalStorageUtils.addToStorage('user', this._user);
    LocalStorageUtils.removeFromStorage(LocalStorageKey.AI_SEARCH_DISABLED_SESSION_KEY);

    this.cookieService.setCookie('auth', 'true', 1, 'novisto.net');
    this.userSubject.next(this._user);

    return true;
  }

  reloadUser(): void {
    this._user = LocalStorageUtils.getFromStorage<User>('user');
    this.userSubject.next(this._user);
  }

  updateUserInfo(first_name: string, last_name: string): boolean {
    if (!this._user) {
      return false;
    } else {
      this._user.first_name = first_name;
      this._user.last_name = last_name;
      LocalStorageUtils.addToStorage('user', this._user);
      this.userSubject.next(this._user);
      return true;
    }
  }

  removeData(): void {
    this.cacheService.clearAll();
    this._user = undefined;
    LocalStorageUtils.removeFromStorage('user');
    this.cookieService.deleteCookie('auth');
    this.userSubject.next(undefined);
  }

  updateUser(token: Token): boolean {
    const tokenParts = token.access_token.split('.');
    if (tokenParts.length < 2 || !this._user) {
      throw new Error('Invalid token');
    }

    const rexp = this._user.rexp;
    this._user = JSON.parse(decodeURIComponent(escape(atob(tokenParts[1]))));
    if (!this._user) {
      throw new Error('Cannot parse user from token');
    }

    this._user.access_token = token.access_token;
    this._user.refresh_token = token.refresh_token;
    this._user.rexp = rexp;
    LocalStorageUtils.addToStorage('user', this._user);

    this.userSubject.next(this._user);
    return true;
  }

  login(username: string, password: string, keepData: boolean = false): Observable<boolean> {
    if (!keepData) {
      this.removeData();
    }

    switch (this._authMethod) {
      case AuthMethod.basic:
        return this.basicLogin(username, password);
      default:
        throw new Error(`Authentication method not implemented: ${this._authMethod}`);
    }
  }

  logout(message?: string, url?: string) {
    if (this._user) {
      this.tokensService.invalidateTokens(undefined, this._user.refresh_token).subscribe();
    }

    this.removeData();
    this.router.navigate(['/'], { state: { data: message, redirectUrl: url } });
    this.dialog.closeAll();
  }

  validatePassword(password: string, token?: string): Observable<string | null> {
    return this.apiService
      .post(
        `${this.servicePath}${this.resource}/validate_password`,
        {
          password,
          reset_code: token,
        },
        undefined,
        false,
      )
      .pipe(
        map((result: ApiResponse<any, any>) => {
          if (result.data.success) {
            return null;
          } else {
            if (result.errors && result.errors.length) {
              return result.errors[0].detail?.reason;
            } else {
              return 'invalid_password';
            }
          }
        }),
      );
  }

  forgotPassword(email: string) {
    return this.apiService.post(`${this.servicePath}${this.resource}/forgot`, { email });
  }

  resetPassword(token: string, password: string) {
    return this.apiService.post(`${this.servicePath}${this.resource}/reset`, {
      reset_code: token,
      new_password: password,
    });
  }

  validateResetCode(token: string): Observable<ApiResponse<null>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/validate_reset_code`, {
      reset_code: token,
    });
  }

  updatePassword(password: string, new_password: string, twofactor_code?: string): Observable<null> {
    return this.apiService.post(`${this.servicePath}${this.resource}/change`, {
      password,
      new_password,
      twofactor_code,
    });
  }

  listPasskeys(): Observable<ApiResponse<BasicAuthPasskey[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/passkeys`);
  }

  deletePasskey(passkeyId: string): Observable<ApiResponse<null>> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/passkeys/${passkeyId}`);
  }

  updatePasskey(passkeyId: string, newDisplayName: string): Observable<ApiResponse<BasicAuthPasskey>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/passkeys/${passkeyId}`, {
      display_name: newDisplayName,
    });
  }

  passkeyRegisterOptionsAuthd(): Observable<ApiResponse<BasicAuthPasskeyRegistrationOptions>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/passkeys/register/options_authd`);
  }

  passkeyRegisterOptionsReset(code: string): Observable<ApiResponse<BasicAuthPasskeyRegistrationOptions>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/passkeys/register/options_reset`, {
      reset_code: code,
    });
  }

  passkeyRegister(
    sessionId: string,
    credential: RegistrationResponseJSON,
    displayName: string,
  ): Observable<ApiResponse<Map<string, string>>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/passkeys/register/new`, {
      session_id: sessionId,
      display_name: displayName,
      credential,
    });
  }

  passkeyAuthOptions(): Observable<ApiResponse<BasicAuthPasskeyAuthOptions>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/passkeys/auth/options`);
  }

  passkeyAuthLogin(sessionId: string, credential: AuthenticationResponseJSON): Observable<boolean> {
    return this.apiService
      .post(
        `${this.servicePath}${this.resource}/passkeys/auth/login`,
        {
          session_id: sessionId,
          credential,
        },
        undefined,
        true,
      )
      .pipe(map((token: Token) => this.saveUser(token)));
  }

  redirectAfterLogin(defaultRedirectUrl: string, loginSuccessfulWithRedirect: boolean): void {
    // We get the redirectURL from storage if user got redirected to login page from the Auth guard
    // to redirect when he was going initially
    const redirectURL = LocalStorageUtils.getFromStorage('redirectURL');
    if (loginSuccessfulWithRedirect) {
      if (redirectURL) {
        void this.router.navigate([redirectURL]);
        LocalStorageUtils.removeFromStorage('redirectURL');
      } else {
        void this.router.navigate([defaultRedirectUrl]);
      }
    }
  }

  generateQRcode(token?: string): Observable<ApiResponse<TwoFactorInit>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/2fa/init`,
      undefined,
      undefined,
      false,
      undefined,
      token ? new HttpHeaders({ 'Content-type': 'application/json' }).append('x-twofactor-token', token) : undefined,
    );
  }

  enableTwoFactor(payload: TwoFactorEnableRequest, token?: string): Observable<void> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/2fa/enable`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
      token ? new HttpHeaders({ 'Content-type': 'application/json' }).append('x-twofactor-token', token) : undefined,
    );
  }

  disableTwoFactor(): Observable<void> {
    return this.apiService.post(`${this.servicePath}${this.resource}/2fa/disable`);
  }

  private basicLogin(email: string, password: string): Observable<boolean> {
    return this.apiService
      .post(
        `${this.servicePath}${this.resource}/login`,
        {
          email,
          password,
        },
        undefined,
        true,
      )
      .pipe(map((token: Token) => this.saveUser(token)));
  }

  twoFactorAuthLogin(username: string, password: string, twoFactorAuthCode: string): Observable<boolean> {
    return this.apiService
      .post(
        `${this.servicePath}${this.resource}/login`,
        {
          email: username,
          password,
          twofactor_code: twoFactorAuthCode,
        },
        undefined,
        true,
      )
      .pipe(map((token: Token) => this.saveUser(token)));
  }

  tokenRefresh(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);

      if (!this.user) {
        this.logout(this.translateService.instant('Your session has expired.'));
      } else {
        this.tokensService.refreshToken(this.user.refresh_token).subscribe({
          next: (token: Token) => {
            this.isRefreshingToken = false;
            this.updateUser(token);
            this.refreshTokenSubject.next(token);

            return next.handle(this.addTokenHeader(request, token));
          },
          error: (error: unknown) => {
            this.isRefreshingToken = false;
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.logout(this.translateService.instant('Your session has expired.'));
            }
            return throwError(() => error);
          },
        });
      }
    }
    return this.refreshTokenSubject.pipe(
      ObservableUtils.filterNullish(),
      take(1),
      switchMap((token: Token) => next.handle(this.addTokenHeader(request, token))),
    );
  }

  getClientLoginInfo(): Observable<ApiResponse<ClientLoginInfo>> {
    return this.apiService.get<ApiResponse<ClientLoginInfo>>(
      `${this.servicePath}${this.dataResource}/client_login_info`,
    );
  }

  evaluateAllFeatureFlags(): Observable<ApiResponse<EvaluatedFeatureFlag[]>> {
    return this.apiService.post<ApiResponse<EvaluatedFeatureFlag[]>>(
      `${this.servicePath}${this.featureFlagResource}/feature_flags/evaluate`,
    );
  }

  private addTokenHeader(request: HttpRequest<unknown>, token: Token) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token.access_token),
    });
  }
}
