<ng-container *ngIf="data$ | async as data">
  <lib-filter-bar
    [initialSearch]="initialSearchOptions?.query?.keywords"
    [secondaryFilters]="data.tableFilters"
    [withSearchBar]="true"
    [searchBarPlaceholder]="
      (aiSearchControl.value ? 'Type and hit enter to search metrics' : 'Search metrics') | translate
    "
    (onSearchChanged)="onSearchChange($event)"
    (searchTriggered)="onSearchChange($event, true)"
    (onFilterChanged)="onFilterChange($event)"
  >
    <div *ngIf="withAISearch" class="ai-search-container ml-4">
      <lib-slide-toggle [control]="aiSearchControl" [label]="'AI Search' | translate"></lib-slide-toggle>
      <lib-chip class="ml-2" [outline]="false" [small]="true" [text]="'Beta' | translate"></lib-chip>
    </div>
  </lib-filter-bar>

  <div class="metric-search-table-height">
    <lib-data-table-remote-data
      *ngIf="!data.isLoading || data.isLoadingNextPage; else dummyTable"
      [isResizable]="true"
      [tableColumns]="metricTableColumns"
      [tableData]="data.metrics"
      [paginationConfig]="data.dataTablePaginationConfig"
      [enableRowSelection]="isCheckable"
      [isExpandable]="enableValueDefinitionSelection"
      [expandAllOption]="!enableValueDefinitionSelection"
      [selectAllOption]="!enableValueDefinitionSelection"
      selectionDataKey="metric_id"
      [selections]="data.selections"
      [expandedRowConfig]="enableValueDefinitionSelection ? expandedRowConfig : undefined"
      [isPaginable]="!withInfiniteScroll"
      [isShowingLoadingAnimation]="data.isLoadingNextPage"
      [showRowSelection]="showRowSelection"
      (checkChanged)="onCheckChanged(data.metrics, $event.selection, $event.all)"
      (selectRow)="onSelectionChanged($event)"
      (pageChanged)="onPageChange($event)"
      (scrolledToBottom)="!data.allDataLoaded ? onPageChange() : null"
    >
    </lib-data-table-remote-data>
  </div>
</ng-container>

<ng-template #dummyTable>
  <lib-dummy [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
</ng-template>

<ng-template #actionCell let-row="row">
  <button
    lib-button
    libClickStopPropagation
    class="ml-2 static"
    data-testid="moreActions"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ row: row }"
  >
    <mat-icon svgIcon="more"></mat-icon>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-aliasMenuItems="row">
    <ng-container *ngFor="let action of actions">
      <ng-container
        *ngTemplateOutlet="actionItem; context: { action: action, aliasMenuItems: aliasMenuItems }"
      ></ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<ng-template #showMoreButton let-items="items">
  <a
    class="btn btn-xs bkg-grey-200 btn-more-link"
    [ngClass]="{ active: trigger.menuOpen }"
    *ngIf="items.length"
    [matMenuTriggerFor]="moreMenu"
    #trigger="matMenuTrigger"
    [matMenuTriggerData]="{ items: items }"
    (click)="stopDefaultClickAction($event)"
  >
    + {{ items.length }}
  </a>
</ng-template>

<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    <div class="ph-3 pv-2">
      <div class="mb-1" *ngFor="let item of items">{{ item.name }}</div>
    </div>
  </ng-template>
</mat-menu>

<ng-template #relatedToCell let-row="row">
  <div class="long-text-wrapper">
    <lib-metric-card-related-metrics
      [metricActionItem]="row | getRelatedMetricItems"
      (handleSelect)="onChipsClick($event)"
    ></lib-metric-card-related-metrics>
  </div>
</ng-template>

<ng-template #compatibleWithCell let-row="row">
  <div class="long-text-wrapper">
    <lib-metric-card-related-metrics
      [metricActionItem]="row | getCompatibleMetricsItems"
      (handleSelect)="onChipsClick($event)"
    ></lib-metric-card-related-metrics>
  </div>
</ng-template>

<ng-template #taxonomyCell let-row="row">
  <div class="long-text-wrapper">
    <lib-show-more-chip-cards
      [chipItems]="row | getTaxonomyChipItems"
      [disableChipAction]="true"
    ></lib-show-more-chip-cards>
  </div>
</ng-template>

<ng-template #actionItem let-action="action" let-row="aliasMenuItems">
  <lib-divider *ngIf="action.id === 'separator'"></lib-divider>
  <a
    mat-menu-item
    *ngIf="action.id !== 'separator'"
    [ngClass]="{ 'color-error': action.id === 'delete' }"
    [disabled]="action.disabled"
    (click)="onAction(action, row)"
  >
    <mat-icon [svgIcon]="action.icon!" class="icon-sm"></mat-icon>
    <span>{{ action.title }}</span>
  </a>
</ng-template>
