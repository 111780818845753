import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

import {
  ActionItem,
  ApiResponse,
  FiscalYear,
  Framework,
  Indicator,
  Industry,
  Metric,
  MetricEquivalent,
  Org,
  ReportTemplate,
  SearchOptions,
  Topic,
  TopicCategory,
  Unit,
  Value,
  ApplicationApiDefinition,
  StandardCodes,
  ClientInfo,
  MinimalIndicator,
  TemplateReport,
  ReportCategoryType,
  FiscalYearStatus,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { AuthService } from '../../common';
import { BaseService } from '../../common/base/base.service';
import { CacheService } from '../../common/cache/cache.service';
import { ClientPeersService } from '../client-peers/client-peers.service';
import { TopicsApiService } from '../../api-services/topics-api-service/topics-api.service';

export interface GetStandardCodeOptions {
  load_framework?: boolean;
  load_tags?: boolean;
  load_topics?: boolean;
  load_industries?: boolean;
  load_metrics?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ClientCoreService extends TopicsApiService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;
  DEFAULT_GET_STANDARD_CODE_OPTIONS: Required<GetStandardCodeOptions> = {
    load_framework: false,
    load_tags: false,
    load_topics: false,
    load_industries: false,
    load_metrics: false,
  };

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private peersService: ClientPeersService,
    private cacheService: CacheService,
    private authService: AuthService,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.core;
  }

  metricPayloadFromSearchOptions(searchOptions: SearchOptions): any {
    const payload: any = {};
    payload.show_imported = true;
    payload.load_value_group_sets = false;
    payload.from = searchOptions.from || 0;
    payload.size = searchOptions.size || this.baseService.defaultPageSize;
    if (searchOptions.query.keywords) {
      payload.keywords = searchOptions.query.keywords;
    }
    payload.filters = {};
    if (searchOptions.filters.industry) {
      payload.filters.industries = [searchOptions.filters.industry.id];
    }
    if (searchOptions.filters.framework) {
      payload.filters.framework_ids = [searchOptions.filters.framework.id];
    }
    if (searchOptions.filters.category) {
      payload.filters.categories = [searchOptions.filters.category.id];
    }
    if (searchOptions.filters.topic) {
      if (searchOptions.filters.topic.action === 'category') {
        payload.filters.topic_categories = [searchOptions.filters.topic.id];
      } else if (searchOptions.filters.topic.action === 'group') {
        payload.filters.topic_groups = [searchOptions.filters.topic.id];
      } else {
        payload.filters.topics = [searchOptions.filters.topic.id];
      }
    }
    if (searchOptions.filters.referenceV2) {
      payload.filters.reference_v2 = searchOptions.filters.referenceV2.item;
    }
    return payload;
  }

  public searchMetrics(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>> {
    const payload: any = this.metricPayloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/search`, payload);
  }

  public getMetric(id: string): Observable<ApiResponse<Metric>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/definitions/` + id);
  }

  public listFrameworks(category?: string): Observable<ApiResponse<Framework[]>> {
    let params = new HttpParams();
    params = category ? params.append('category', category) : params;
    return this.apiService.get(`${this.servicePath}${this.resource}/frameworks`, { params });
  }

  public listThirdPartyFrameworks(): Observable<ApiResponse<Framework[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/frameworks/third-party-frameworks`);
  }

  public listAllIndustries(): Observable<ApiResponse<Industry[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/industries?return_all_industries=true`);
  }

  public listIndustries(): Observable<ApiResponse<Industry[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/industries`);
  }

  public listOrgs(): Observable<ApiResponse<Org[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/orgs`);
  }

  public listFiscalYears(org_ids?: string[], business_unit_ids?: string[]): Observable<ApiResponse<any[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/fiscal_years/list`, {
      org_ids,
      business_unit_ids,
    });
  }

  public getYear(year: number, org_id: string): Observable<FiscalYear> {
    const fiscalYear: FiscalYear = {
      id: '',
      name: '',
      year,
      start: `${year} + '-01-01'`,
      end: `${year} + '-12-31'`,
      unit_id: '',
      status: FiscalYearStatus.OPEN,
    };
    return this.listFiscalYears([org_id]).pipe(
      map(
        (fiscalYears: ApiResponse<any[]>) =>
          fiscalYears.data[0].business_units![0].fiscal_years.find(
            (fiscalYear: FiscalYear) => +fiscalYear.start.substring(0, 4) == year,
          ) || fiscalYear,
      ),
    );
  }

  public listAllOrgs(
    load_industries: boolean = false,
    search_term?: string,
    industry_id?: string,
    load_is_favorited?: boolean,
    active?: boolean,
  ): Observable<ApiResponse<Org[]>> {
    let params = new HttpParams().append('return_all_orgs', 'true');
    params = params.append('load_industries', load_industries);

    if (search_term) {
      params = params.append('search_term', search_term);
    }

    if (industry_id) {
      params = params.append('industry_ids', industry_id);
    }

    if (active != null) {
      params = params.append('active', active);
    }

    const response = this.apiService.get(`${this.servicePath}${this.resource}/orgs`, { params });

    if (load_is_favorited) {
      const favoriteOrgsCall = this.peersService.listFavoriteOrgs();
      const apiCalls = [favoriteOrgsCall, response];

      return forkJoin(apiCalls).pipe(
        map((results) => {
          const favoriteOrgsId = results[0].data.map((i: any) => i.id);
          const coreOrgs = results[1].data.map((item: any) => {
            item.favorited = favoriteOrgsId.includes(item.id);
            return item;
          });
          const orgApiResponse = results[1];
          orgApiResponse.data = coreOrgs;
          return orgApiResponse;
        }),
      );
    }

    return response;
  }

  public searchOrgs(searchOptions?: SearchOptions): Observable<ApiResponse<Org[]>> {
    if (searchOptions) {
      return this.listAllOrgs(
        true,
        searchOptions.query.keywords,
        searchOptions.filters?.industry?.id,
        <boolean>searchOptions.custom_properties?.load_is_favorited,
        <boolean>searchOptions.custom_properties?.active,
      );
    }
    return this.listAllOrgs(true);
  }

  public listMetrics(payload?: any): Observable<ApiResponse<Indicator[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/list`, payload);
  }

  public listMetricEquivalents(metricId: string, match?: string): Observable<ApiResponse<MetricEquivalent[]>> {
    let params = new HttpParams().append('metric_id', `${metricId}`);
    params = match ? params.append('match', match) : params;
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/equivalents`, { params });
  }

  public listUnitFamilies(): Observable<ApiResponse<Unit[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/units/families`);
  }

  public listUnits(family?: string): Observable<ApiResponse<Unit[]>> {
    let params = new HttpParams();
    if (family) {
      params = family ? params.append('family', family) : params;
      const allUnits = <ActionItem<Unit>[]>this.cacheService.getCategory('unit:undefined');
      const units = allUnits.filter((x) => x.item?.family === family);
      if (units.length) {
        return of({ data: units.map((x) => x.item!), errors: [], meta: {} });
      }
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/units`, { params });
  }

  public listTopics(): Observable<ApiResponse<Topic[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/topics`).pipe(
      map((results: ApiResponse<TopicCategory[]>) => {
        const topics: Topic[] = [];
        for (const topicCategory of results.data) {
          for (const topicGroup of topicCategory.topic_groups) {
            for (const topic of topicGroup.topics) {
              topics.push(topic);
            }
          }
        }
        return {
          meta: {
            count: topics.length,
          },
          errors: [],
          data: topics,
        };
      }),
    );
  }

  public listTopicCategories(): Observable<ApiResponse<TopicCategory[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/topics`);
  }

  public getStandardCode(
    standard_code_id: string,
    options: GetStandardCodeOptions = {},
  ): Observable<ApiResponse<StandardCodes>> {
    const assembledOptions = { ...this.DEFAULT_GET_STANDARD_CODE_OPTIONS, ...options };

    let params = new HttpParams();
    params = params.append('load_framework', assembledOptions.load_framework);
    params = params.append('load_tags', assembledOptions.load_tags);
    params = params.append('load_topics', assembledOptions.load_topics);
    params = params.append('load_industries', assembledOptions.load_industries);
    params = params.append('load_metrics', assembledOptions.load_metrics);
    return this.apiService.get(`${this.servicePath}${this.resource}/standard_codes/${standard_code_id}`, { params });
  }

  // Report templates

  payloadFromSearchOptions(searchOptions: SearchOptions): any {
    const payload: any = { filters: {} };
    //if (searchOptions.query.keywords) payload.keywords = searchOptions.query.keywords;
    if (searchOptions.filters.year) {
      payload.filters.report_frequencies = [searchOptions.filters.year.id];
    }
    if (searchOptions.filters.category) {
      payload.filters.category = searchOptions.filters.category.id;
    }
    if (searchOptions.filters.topic) {
      if (searchOptions.filters.topic.action === 'category') {
        payload.filters.topics = [searchOptions.filters.topic.id];
      } else if (searchOptions.filters.topic.action === 'group') {
        payload.filters.topics = [searchOptions.filters.topic.id];
      } else {
        payload.filters.topics = [searchOptions.filters.topic.id];
      }
    }
    if (searchOptions.filters.framework) {
      payload.filters.frameworks = [searchOptions.filters.framework.id];
    }
    if (searchOptions.filters.industry) {
      payload.filters.industries = [searchOptions.filters.industry.id];
    }
    if (searchOptions.sort) {
      payload.order_by = 'name';
    } else {
      payload.order_by = 'updated';
      payload.order_by_direction = 'desc';
    }
    return payload;
  }

  searchTemplates(searchOptions: SearchOptions): Observable<ApiResponse<ReportTemplate[]>> {
    const payload: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/report_templates/list`, payload).pipe(
      map((x: ApiResponse<ReportTemplate[]>) => {
        x.data = x.data.filter((y) => y.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase()));
        return x;
      }),
    );
  }

  listTemplates(
    reportCategoryType?: ReportCategoryType,
    searchOptions?: SearchOptions,
    active?: boolean,
  ): Observable<ApiResponse<TemplateReport[]>> {
    const payload = {
      filters: {
        category: reportCategoryType ?? 'questionnaire',
        reference_v2: searchOptions?.filters.referenceV2?.item,
        active,
      },
      page: searchOptions?.from && searchOptions.size ? searchOptions.from / searchOptions.size + 1 : undefined,
      page_size: searchOptions?.size,
    };

    if (active) {
      payload.filters.active = active;
    }

    return this.apiService.post(`${this.servicePath}${this.resource}/report_templates/list`, payload);
  }

  getTemplate(template_id: string): Observable<ApiResponse<ReportTemplate>> {
    let params = new HttpParams();
    params = params.append('include_sections', 'true');
    params = params.append('include_metrics', 'true');
    return this.apiService.get(`${this.servicePath}${this.resource}/report_templates/${template_id}`, { params });
  }

  getClientInfo(): Observable<ApiResponse<ClientInfo>> {
    return this.apiService.get<ApiResponse<ClientInfo>>(`${this.servicePath}${this.resource}/client_info`);
  }

  // utility methods

  public getValueUnit(value: Value, units: Unit[]): string {
    if (value.type_details?.units) {
      const unit = units.find((unit) => unit.code === (value.unit ?? value.type_details.units));

      return unit ? (unit.symbol ? unit.symbol : '') : '';
    }
    return '';
  }

  public getMetricMissingDependencies(metricId: string): Observable<ApiResponse<MinimalIndicator[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/missing_dependencies`);
  }
}
