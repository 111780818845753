/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  MetricGroup,
  MetricGroupNameCheck,
  MetricGroupRequest,
  MetricGroupsFilteringOptions,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientMetricGroupsService {
  private apiName: keyof ApplicationApiDefinition = 'collect';
  private resource: string;
  private servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.metricGroups;
  }

  public searchMetricGroups(filteringOptions: MetricGroupsFilteringOptions): Observable<ApiResponse<MetricGroup[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metric_groups/search`, filteringOptions);
  }

  public listMetricGroups(): Observable<ApiResponse<MetricGroup[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metric_groups`);
  }

  public getMetricGroup(metricGroupId: string): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metric_groups/${metricGroupId}`);
  }

  public isMetricGroupNameAvailable(metricGroupName: string): Observable<ApiResponse<MetricGroupNameCheck>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metric_groups/name_check/${metricGroupName}`);
  }

  public createMetricGroup(metricGroupRequest: MetricGroupRequest): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metric_groups`, metricGroupRequest);
  }

  public updateMetricGroup(
    metricGroupId: string,
    metricGroupRequest: MetricGroupRequest,
  ): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metric_groups/${metricGroupId}`,
      metricGroupRequest,
    );
  }

  public activateMetricGroup(metricGroupId: string): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metric_groups/${metricGroupId}/activate`);
  }

  public deactivateMetricGroup(metricGroupId: string): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metric_groups/${metricGroupId}/deactivate`);
  }

  public createMetricGroupMetric(
    metricGroupId: string,
    metricId: string,
    indicatorId: string,
    position: number,
  ): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metric_groups/${metricGroupId}/metrics/${metricId}`,
      { position, indicator_id: indicatorId },
    );
  }

  public deleteMetricGroupMetric(metricGroupId: string, metricId: string): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metric_groups/${metricGroupId}/metrics/${metricId}`,
    );
  }

  public moveMetricGroupMetric(
    metricGroupId: string,
    metricId: string,
    position: number,
  ): Observable<ApiResponse<MetricGroup>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metric_groups/${metricGroupId}/metrics/${metricId}/move/${position}`,
    );
  }
}
