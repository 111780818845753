import { Metric, MetricCategory } from '../../models';

export class MetricUtils {
  public static isRefV2Metric(metric: Metric): boolean {
    return Boolean(metric.reference_v2) && metric.category === MetricCategory.REFERENCE;
  }

  public static isRefV2OrTPM(metric: Metric): boolean {
    return MetricUtils.isRefV2Metric(metric) || metric.category === MetricCategory.THIRD_PARTY;
  }
}
