import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  isQuantitativeRule,
  isTableGroup,
  MetricEditorGroup,
  TableGroup,
  ValueGroup,
  ValueGroupSet,
  isFieldBypassConsolidation,
} from '../../models';
import { ValueGroupFormGroup } from './valueGroupFormGroup';
import { ConditionalTriggerService } from '../services/conditional-trigger.service';
import { UpsertValueGroup, UpsertValueGroupSet } from './upsertValue';
import { TableFormGroup } from './tableFormGroup';
import { MetricEditorFormGroup } from './metricEditorFormGroup';
import { ValueGroupService } from '../services/value-group.service';
import { BehaviorSubject } from 'rxjs';
import { WaitableForNextUpdate } from './waitableForNextUpdate';

export class ValueGroupSetForm extends UntypedFormGroup implements WaitableForNextUpdate {
  valueGroupSet: ValueGroupSet;
  private _isWaitingForNextUpdate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly isWaitingForNextUpdate$ = this._isWaitingForNextUpdate$.asObservable();

  constructor(
    initialValueGroupSetRef: ValueGroupSet,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
    readonly conditionalTriggerService: ConditionalTriggerService = new ConditionalTriggerService(),
  ) {
    super(fb.group({}).controls);

    this.valueGroupSet = initialValueGroupSetRef;
    this.addValueGroupForms(this.valueGroupSet);
  }

  public waitForNextUpdate(): void {
    this._isWaitingForNextUpdate$.next(true);
    this.allFormGroups().forEach((group) => group.waitForNextUpdate());
  }

  public allFormGroups(): MetricEditorFormGroup[] {
    return (Object.values(this.controls) as MetricEditorFormGroup[]).sort(
      ValueGroupSetForm.sortGroupFormsByPositionAndSubPosition,
    );
  }

  public allDisplayedFormGroups(): MetricEditorFormGroup[] {
    return this.allFormGroups().filter((formGroup) =>
      formGroup instanceof ValueGroupFormGroup
        ? this.conditionalTriggerService.isElementDisplayed(formGroup.valueGroupRef.conditional_triggers, this)
        : true,
    );
  }

  public groupFormGroups(): ValueGroupFormGroup[] {
    return (Object.values(this.controls) as MetricEditorFormGroup[]).filter(
      (fg) => fg instanceof ValueGroupFormGroup,
    ) as ValueGroupFormGroup[];
  }

  public tableFormGroups(): TableFormGroup[] {
    return (Object.values(this.controls) as MetricEditorFormGroup[]).filter(
      (fg) => fg instanceof TableFormGroup,
    ) as TableFormGroup[];
  }

  public updateValueGroupSet(vgs: ValueGroupSet): void {
    this.valueGroupSet = vgs;
    this._isWaitingForNextUpdate$.next(false);
    if (this.valueGroupSet.value_groups) {
      this.handleDeletedRepeatableValueGroups(this.valueGroupSet.value_groups);
      const mergedGroups = this.mergeTableGroup(this.valueGroupSet.value_groups);
      mergedGroups.forEach((updatedGroup) => {
        if (isTableGroup(updatedGroup)) {
          this.handleUpdatedTableGroup(updatedGroup);
        } else {
          this.handleUpdatedValueGroup(updatedGroup);
        }
      });
    }
  }

  public isConsolidated(): boolean {
    return this.valueGroupSet.consolidated;
  }

  public businessUnitLevel(): number | undefined {
    return this.valueGroupSet.business_unit_level;
  }

  private handleUpdatedTableGroup(updatedTableGroup: TableGroup): void {
    const existingTableFormGroup = this.tableFormGroups().find((tfg) => tfg.id === updatedTableGroup.id);
    if (existingTableFormGroup) {
      existingTableFormGroup.updateGroup(updatedTableGroup);
    } else {
      this.addTableGroupForm(updatedTableGroup);
    }
  }

  private handleUpdatedValueGroup(updatedValueGroup: ValueGroup): void {
    const existingValueGroupFormGroup = this.groupFormGroups().find((gfg) =>
      ValueGroupSetForm.compareByIdOrValueDefinitionGroupIdAndSubposition(gfg, updatedValueGroup),
    );
    if (existingValueGroupFormGroup) {
      existingValueGroupFormGroup.updateGroup(updatedValueGroup);
    } else {
      this.addValueGroupForm(updatedValueGroup);
    }
  }

  public applyConditionalTriggers(): void {
    this.groupFormGroups().forEach((groupForm) => {
      const isDisplayed = this.conditionalTriggerService.isElementDisplayed(
        groupForm.valueGroupRef.conditional_triggers,
        this,
      );
      if (isDisplayed) {
        groupForm.enable({ emitEvent: false });
        groupForm.valueFormControls().forEach((valueFormControl) => {
          const isDisplayed = this.conditionalTriggerService.isElementDisplayed(
            valueFormControl.valueRef.conditional_triggers,
            this,
          );
          if (isDisplayed) {
            valueFormControl.enable({ emitEvent: false });
          } else {
            valueFormControl.disable({ emitEvent: false });
            valueFormControl.reset(null, { emitEvent: false });
          }
        });
      } else {
        groupForm.disable({ emitEvent: false });
        groupForm.reset({}, { emitEvent: false });
      }
    });
  }

  public applyConsolidationRules(): void {
    [...this.groupFormGroups(), ...this.tableFormGroups()].forEach((groupForm) => {
      groupForm.valueFormControls().forEach((valueFormControl) => {
        const isConsolidatedField = isQuantitativeRule(valueFormControl.valueRef.consolidation_rule);
        const bypassConsolidation = isFieldBypassConsolidation(
          valueFormControl.valueRef.bypass_consolidation_levels as number[] | null,
          this.businessUnitLevel(),
        );

        if (isConsolidatedField && !bypassConsolidation) {
          valueFormControl.disable({ emitEvent: false });
        }
      });
    });
  }

  private handleDeletedRepeatableValueGroups(valueGroups: ValueGroup[]): void {
    const updatedValueGroupIds = valueGroups.map((vg) => vg.id);
    this.groupFormGroups()
      .filter((groupFormGroup) => groupFormGroup.repeatable)
      .filter((valueGroup) => !updatedValueGroupIds.includes(valueGroup.id))
      .forEach((deletedGroup) => {
        const deletedSubPosition = deletedGroup.subposition;
        const groupName = this.getControlName(deletedGroup);
        if (groupName) {
          this.removeControl(groupName);
          this.groupFormGroups()
            .filter((gfg) => gfg.subposition > deletedSubPosition)
            .forEach((gfg) => {
              gfg.valueGroupRef.subposition = gfg.subposition - 1;
            });
        }
      });
  }

  private getControlName(formGroup: AbstractControl): string | null {
    return Object.keys(this.controls).find((name) => formGroup === this.controls[name]) ?? null;
  }

  public toUpsertValueGroupSet(upsertValueGroups: UpsertValueGroup[]): UpsertValueGroupSet {
    return {
      id: this.valueGroupSet.id,
      frequency_code: this.valueGroupSet.frequency_code!,
      business_unit_id: this.valueGroupSet.business_unit_id,
      data_request_id: this.valueGroupSet.data_request_id!,
      value_groups: upsertValueGroups,
    };
  }

  public triggerValidations(): void {
    this.markAllAsTouched();
    this.updateValueAndValidity();
  }

  public isValidOrDisabled(): boolean {
    return this.valid || this.disabled;
  }

  private addValueGroupForms(initialVgs: ValueGroupSet): void {
    if (initialVgs.value_groups) {
      this.mergeTableGroup(initialVgs.value_groups).forEach((group) => {
        if (isTableGroup(group)) {
          this.addTableGroupForm(group);
        } else {
          this.addValueGroupForm(group);
        }
      });
    }
  }

  private mergeTableGroup(initialValueGroups: ValueGroup[]): MetricEditorGroup[] {
    const groupingByTableId: Record<string, ValueGroup[]> = initialValueGroups
      .filter((vg) => vg.table_id != null)
      .reduce(
        (grouping: Record<string, ValueGroup[]>, vg) => ({
          ...grouping,
          [vg.table_id as string]: [...(grouping[vg.table_id as string] ?? []), vg],
        }),
        {},
      );

    const tableGroups: TableGroup[] = Object.entries(groupingByTableId)
      .filter(([key]) => key != '')
      .map(([key, valueGroups]: [string, ValueGroup[]]) => ({
        id: key,
        table_id: key,
        indicator_id: valueGroups[0].indicator_id,
        position: valueGroups[0].position,
        valueGroups,
      }));

    const valueGroups = initialValueGroups.filter((vg) => vg.table_id == null);

    return [...valueGroups, ...tableGroups].sort((a, b) => a.position - b.position);
  }

  private addTableGroupForm(tableGroup: TableGroup): void {
    this.addControl(`table-${tableGroup.table_id}`, new TableFormGroup(tableGroup));
  }

  private addValueGroupForm(valueGroup: ValueGroup): void {
    this.addControl(`group-${ValueGroupService.getUniqueIdentifier(valueGroup)}`, new ValueGroupFormGroup(valueGroup));
  }

  private static sortGroupFormsByPositionAndSubPosition(a: MetricEditorFormGroup, b: MetricEditorFormGroup): number {
    return a.position - b.position || a.subposition - b.subposition;
  }

  private static compareByIdOrValueDefinitionGroupIdAndSubposition(
    valueGroupForm: ValueGroupFormGroup,
    valueGroup: ValueGroup,
  ): boolean {
    return (
      valueGroupForm.valueGroupRef.value_definition_group_id === valueGroup.value_definition_group_id &&
      (valueGroupForm.valueGroupRef.position ?? 1) === (valueGroup.position ?? 1) &&
      (valueGroupForm.valueGroupRef.subposition ?? 1) === (valueGroup.subposition ?? 1)
    );
  }
}
