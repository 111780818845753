<div class="doc-card-container" [ngClass]="{ 'not-uploaded': !isFileAdded }">
  <div class="fx-row" *ngIf="fileValue.file_id && fileDoc">
    <div
      class="card card-link w-25 file mb-2"
      [ngClass]="{ 'card-downloading': isDownloading }"
      [attr.data-testid]="'file-card-' + fileDoc.id"
    >
      <div *ngIf="!isDownloading" class="fx-row justify-space-between">
        <div class="fx-row mr-1">
          <mat-icon
            class="icon-sm mr-2 color-grey-600"
            [ngClass]="{ 'color-primary-600': isFileAdded }"
            svgIcon="link"
          ></mat-icon>
          <button
            lib-button
            buttonType="link"
            [attr.data-testid]="'fileLink-' + fileDoc.id"
            (click)="downloadDoc()"
            [ngClass]="{ disabled: !isFileAdded }"
          >
            {{ fileDoc.name }}.{{ fileDoc.format }}
          </button>
        </div>
        <button
          type="button"
          *ngIf="!fileFormGroup?.disabled"
          [attr.aria-label]="'deleteIcon-' + fileDoc.id"
          (click)="deleteDocument()"
          class="icon-btn"
        >
          <mat-icon class="icon-sm" svgIcon="trash"></mat-icon>
        </button>
      </div>

      <div *ngIf="isDownloading" class="fx-row justify-space-between white-spinner">
        <span class="color-white">{{ fileDoc.name }}.{{ fileDoc.format }}</span>
        <lib-spinner ariaLabel="download-spinner" [diameter]="15"></lib-spinner>
      </div>
    </div>
    <div class="fx-row ml-3 align-center" *ngIf="!isFileAdded">
      <mat-icon class="color-error mr-1" svgIcon="info"></mat-icon>
      <span> {{ "Complete all required fields to upload the file." | translate }}</span>
    </div>
  </div>

  <div class="fx-row justify-end" *ngIf="index | isFileGroupDeletable: isFileAdded : fileValue.file_id">
    <button class="icon-btn" type="button" (click)="deleteDocument()" [attr.aria-label]="'deleteIcon-' + index">
      <mat-icon svgIcon="trash"></mat-icon>
    </button>
  </div>

  <form
    *ngIf="fileFormGroup"
    [formGroup]="fileFormGroup"
    class="form"
    (libBeforeUnload)="updateValueFormControl()"
    data-testid="url-section"
  >
    <lib-text-input
      *ngIf="fileFormGroup.docUrl"
      data-testid="doc-url"
      class="medium-large"
      [control]="fileFormGroup.docUrl"
      [label]="'URL' | translate"
      (handleBlur)="updateValueFormControl()"
    ></lib-text-input>
    <lib-text-input
      data-testid="page-number"
      class="small"
      [control]="fileFormGroup.pageNumber"
      [label]="'Page Number' | translate"
      (handleBlur)="updateValueFormControl()"
    ></lib-text-input>
    <lib-text-area-input
      class="large"
      data-testid="explanation"
      [control]="fileFormGroup.explanation"
      [label]="explanationLabel"
      (handleBlur)="updateValueFormControl()"
      [maxLength]="explanationMaxLength"
    ></lib-text-area-input>
  </form>
</div>
