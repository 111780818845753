import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import {
  CalculatedTypeDetails,
  NumberTypeDetails,
  ValueDefinitionSize,
  SOURCE_CONFIGURATION,
  YearToDateCalculationLabel,
  Frequency,
} from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';

@Component({
  selector: 'lib-metric-editor-frequency-field',
  templateUrl: './metric-editor-frequency-field.component.html',
  styleUrls: ['./metric-editor-frequency-field.component.scss'],
})
export class MetricEditorFrequencyFieldComponent implements OnInit {
  @Input() control?: ValueFormControl<NumberTypeDetails | CalculatedTypeDetails>;
  @Input() frequenciesControl?: ValueFormControl[];
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input() forceAllowDecimals = false;
  @Input() maxDecimals?: number;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<FocusEvent>();

  yearToDateCalculation: string = '';
  indicatorName: string | undefined = '';
  frequency: Frequency | undefined = Frequency.YEARLY;

  ngOnInit(): void {
    this.indicatorName = this.control?.valueRef.label;

    const ytdCalcKey = this.control?.valueRef?.value_definition_frequency?.year_to_date_calculation;
    this.yearToDateCalculation = ytdCalcKey ? YearToDateCalculationLabel[ytdCalcKey] : '';
    this.frequency = this.control?.valueRef?.value_definition_frequency?.frequency;

    this.frequenciesControl?.sort(
      (a, b) =>
        new Date(a.valueRef.fiscal_year_period!.start).getTime() -
        new Date(b.valueRef.fiscal_year_period!.start).getTime(),
    );
  }

  formatLabel(periodLabel: string, startDateStr?: string, frequency?: string): string {
    if (frequency === Frequency.MONTHLY && startDateStr) {
      const startDate = dayjs(startDateStr);
      if (!startDate.isValid()) {
        return periodLabel;
      }
      return startDate.format('MMMM');
    }
    return periodLabel;
  }

  formatDateRange(startDateStr?: string, endDateStr?: string): string {
    if (!startDateStr || !endDateStr) {
      return '';
    }
    const startDate = dayjs(startDateStr);
    const endDate = dayjs(endDateStr);
    if (!startDate.isValid() || !endDate.isValid()) {
      return '';
    }
    return `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
  }

  setBlur() {}

  setFocus() {}

  protected readonly ValueDefinitionSize = ValueDefinitionSize;
}
