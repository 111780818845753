<ng-container *ngIf="data$ | async as data">
  <lib-info-banner
    *ngIf="missingItems.length"
    [message]="
      'The following categories require a default list to be selected: {categories}'
        | translate: { categories: missingItems | map: bindOptionListCategoryDefaultItemLabelFn | join: ', ' }
    "
    type="error"
  ></lib-info-banner>

  <h4 [ngClass]="{ 'mt-6': missingItems.length }">{{ "Set category defaults" | translate }}</h4>
  <p class="mb-5">{{ "All categories require a default option list to be selected" | translate }}</p>

  <div *ngFor="let item of data | objectValues; trackBy: trackBy" class="category-row">
    <span class="category-label mb-4">{{ item.category.name }}</span>
    <div class="category-select-container">
      <lib-searchable-select-input
        [options]="item.options"
        [isLoadingOptions]="item.isLoading"
        [control]="item.control"
        [reachedEnd]="item.reachedEnd"
        [minMenuScrollItems]="0"
        [bindOptionLabelFn]="bindOptionLabelFn"
        [compareFn]="compareFn"
        (openedChange)="openedChange($event, data[item.category.id])"
        (loadOptions)="loadOptionLists(data[item.category.id], $event.from, $event.searchValue)"
      ></lib-searchable-select-input>
    </div>
    <span class="mb-4">
      <mat-icon *ngIf="item.control.value; else missing" class="check-icon" svgIcon="check"></mat-icon>
      <ng-template #missing><mat-icon class="close-icon" svgIcon="close"></mat-icon></ng-template>
    </span>
  </div>
</ng-container>
