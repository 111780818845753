import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith, combineLatest, BehaviorSubject, Observable, of } from 'rxjs';

import { ActionItem, SOURCE_CONFIGURATION, Source } from '../../models';
import { TranslateService } from '../../services/common';
import { SearchService } from '../services/search.service';
import { SourceMenuItemConversionService } from '../services/source-menu-item-conversion.service';

@Component({
  selector: 'lib-source-menu-filter',
  templateUrl: './source-menu-filter.component.html',
  styleUrls: ['./source-menu-filter.component.scss'],
})
export class SourceMenuFilterComponent implements OnInit {
  @Input() initialSources: ActionItem<Source>[] = [];
  @Input() minMenuScrollItems: number = 10;
  @Input() selectedSource?: ActionItem<Source>;
  @Input() allSourcesItem?: ActionItem;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;

  @Output() setItem = new EventEmitter<ActionItem | undefined>();
  @Output() switchToConsolidatedView = new EventEmitter();

  allSourcesTitle: string = 'All';

  readonly staticCategories: string[] = [
    this.translateService.instant('Consolidated'),
    this.translateService.instant('Sources'),
  ];
  readonly dynamicConsolidationSource: ActionItem<Source> = {
    id: '',
    title: this.translateService.instant('Consolidated'),
  };

  sources: ActionItem<Source>[] = [];
  filteredSources$: Observable<ActionItem<Source>[]> = of([]);
  categories: string[] = [];
  sourceFormControl: FormControl<string | null> = new FormControl<string>('');
  sources$ = new BehaviorSubject<ActionItem<Source>[]>([]);

  constructor(
    private translateService: TranslateService,
    private searchService: SearchService,
    private sourceMenuItemConversionService: SourceMenuItemConversionService,
  ) {
    this.filteredSources$ = combineLatest([
      this.sources$,
      this.sourceFormControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([sources, value]: [ActionItem<Source>[], string | null]) =>
        this.searchService.filterItems(sources, value as string),
      ),
    );
  }

  ngOnInit(): void {
    switch (this.sourceConfiguration) {
      case SOURCE_CONFIGURATION.dynamic_consolidation:
        if (!this.initialSources.find((initialSource) => initialSource.id === '') && this.initialSources.length > 1) {
          this.sources.push(this.dynamicConsolidationSource);
        }
        break;
      case SOURCE_CONFIGURATION.single_group:
        this.categories = this.staticCategories;
        break;
      case SOURCE_CONFIGURATION.hierarchy:
        this.initialSources = this.sourceMenuItemConversionService.convertActionItemsToFlattenHierarchyItems(
          this.initialSources,
        );
        break;
      case SOURCE_CONFIGURATION.groups:
        this.categories = this.staticCategories;
        break;
      case SOURCE_CONFIGURATION.single_source:
        const index = this.initialSources.findIndex((initialSource) => initialSource.item?.consolidation);
        if (index > -1) {
          this.initialSources.splice(index, 1);
        }
        break;
      default:
        break;
    }
    this.sources.push(...this.initialSources);
    this.sources$.next(this.sources);
  }

  filterItemsFunction(item: ActionItem<Source>, category?: string): boolean {
    if (category === 'Consolidated') {
      return Boolean(item.item?.consolidation);
    }
    return !item.item?.consolidation;
  }

  setSource(source: ActionItem<Source>): void {
    source.id || source.title === this.allSourcesTitle
      ? this.setItem.emit(source)
      : this.switchToConsolidatedView.emit();
  }
}
