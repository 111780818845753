import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeTableColumn, TreeTableDataNode, TreeTableEvent } from '../../models';
import { ActionItem, Source } from '../../models';

@Component({
  selector: 'lib-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
})
export class TreeTableComponent<T> implements OnInit {
  private _dataSource!: TreeTableDataNode<T>[];

  @Input() columns?: TreeTableColumn<T>[];
  @Input() selectable: boolean = false;
  @Input() showHeader: boolean = false;
  @Input() allNodesSelectable: boolean = true;
  @Input() expandAllNodes: boolean = false;
  @Output() nodeSelected: EventEmitter<TreeTableDataNode<T>> = new EventEmitter();
  @Output() nodeUnselected: EventEmitter<TreeTableDataNode<T>> = new EventEmitter();
  @Output() rowClicked: EventEmitter<Source> = new EventEmitter();

  @Input()
  set dataSource(data: TreeTableDataNode<T>[]) {
    this._dataSource = data;
    this.updateExpandedStates();
  }

  get dataSource(): TreeTableDataNode<T>[] {
    return this._dataSource;
  }

  selectedNodes: TreeTableDataNode<T>[] = [];

  expandedNodes: Set<string> = new Set();

  ngOnInit() {
    if (this.expandAllNodes) {
      this.dataSource.forEach((node) => this.recursiveExpandNode(node));
    }
  }

  public recursiveExpandNode(node: TreeTableDataNode<T>) {
    node.expanded = true;
    this.expandedNodes.add(node.id);
    if (node.children) {
      node.children.forEach((child) => this.recursiveExpandNode(child));
    }
  }

  public nodeSelect(event: TreeTableEvent<T>): void {
    this.nodeSelected.emit(event.node);
  }

  public nodeUnselect(event: TreeTableEvent<T>): void {
    this.selectedNodes = this.selectedNodes.filter((node) => node.id !== event.node.id);
    this.nodeUnselected.emit(event.node);
  }

  public nodeExpand(event: TreeTableEvent<T>): void {
    event.node.expanded = true; // Directly set the expanded property to true
    this.expandedNodes.add(event.node.id);
  }

  public nodeCollapse(event: TreeTableEvent<T>): void {
    event.node.expanded = false;
    this.expandedNodes.delete(event.node.id);
  }

  public rowClick(sourceActionItem: ActionItem<Source>): void {
    this.rowClicked.emit(sourceActionItem.item);
  }

  private updateExpandedStates(): void {
    if (!this._dataSource) {
      return;
    }
    this._dataSource.forEach((node) => this.recursiveUpdateExpanded(node));
  }

  private recursiveUpdateExpanded(node: TreeTableDataNode<T>): void {
    node.expanded = this.expandedNodes.has(node.id);
    if (node.selected) {
      this.selectedNodes.push(node);
    }
    if (node.children) {
      node.children.forEach((child) => this.recursiveUpdateExpanded(child));
    }
  }
}
