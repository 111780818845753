export abstract class BaseJobInProgressException extends Error {
  protected constructor(message: string) {
    super(message);
  }
}

export class SPJobInProgressException extends BaseJobInProgressException {
  constructor() {
    super('S&P submit job is already in progress');
  }
}

export class CDPJobInProgressException extends BaseJobInProgressException {
  constructor() {
    super('CDP submit job is already in progress');
  }
}

export class PasswordReuseException extends Error {
  constructor() {
    super('Password must be different from last used password');
  }
}

export class CDPInvalidTokenException extends Error {
  constructor() {
    super('The token used to access CDP was invalid');
  }
}

export class CDPInvalidConfigurationException extends Error {
  constructor() {
    super('CDP integration is not rightly configured');
  }
}

export class CDPResourceNotFoundException extends Error {
  constructor() {
    super('CDP resource not found');
  }
}

export class CDPUnauthorizedException extends Error {
  constructor() {
    super('Operation unauthorized on the CDP Portal');
  }
}

export class CDPGeneralException extends Error {}

export class RequestAlreadyImportedException extends Error {
  constructor() {
    super('The request you’re trying to access has already been imported.');
  }
}

export class RequestArchivedException extends Error {
  constructor() {
    super('The request you’re trying to access is archived.');
  }
}

export class MinimumValueDefinitionValidationException extends Error {
  constructor() {
    super('no_value_definition_matching_mapping');
  }
}

export class ConvertBusinessUnitWithDataException extends Error {
  constructor() {
    super('You can’t automatically convert a source that contains data (potential data loss)');
  }
}

export class ConvertBusinessUnitOnLevelFourException extends Error {
  constructor() {
    super(
      'You can’t have a consolidated source on this level of your hierarchy. Please move to a different parent first',
    );
  }
}

export class ConvertLastConsolidatedBusinessUnitException extends Error {
  constructor() {
    super('You can’t convert the last consolidated source');
  }
}

export class ConvertConsolidatedBusinessUnitWithChildrenException extends Error {
  constructor() {
    super('The consolidated source can’t have any children');
  }
}

export class ReportValidationErrorException extends Error {
  constructor() {
    super('report_validation_error');
  }
}
