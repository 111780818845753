<div class="field-position-relative" [ngSwitch]="valueDefinitionDisplayType">
  <div
    [class]="'field-focus ' + (valueFormControl.valueRef | valueDefinitionFieldSize)"
    [libIsActiveField]="focusedField$ | async"
    [focusFieldUniqueId]="focusFieldUniqueId$ | async"
    [value]="valueFormControl.valueRef"
    [focusId]="focusId"
    [ngClass]="{
      focused: isFocusable && isFocusEnabled,
      'has-comments':
        this.valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
        !!this.valueFormControl.valueRef.unresolved_rejection_comments_and_replies,
    }"
    (mousedown)="selectFieldForFocus(valueFormControl.valueRef, valueGroup)"
    data-testid="field-focus"
  >
    <ng-container
      *ngSwitchCase="
        [eValueDefinitionDisplayType.number, eValueDefinitionDisplayType.calculated]
          | multiSwitchCase: valueDefinitionDisplayType
      "
    >
      <lib-metric-editor-frequency-field
        data-testid="frequency-numeric-input"
        [control]="valueFormControl"
        [frequenciesControl]="frequenciesControls"
        [sourceConfiguration]="sourceConfiguration"
      ></lib-metric-editor-frequency-field>
    </ng-container>

    <!-- <div
      lib-field-comment-counter
      *ngIf="
        valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
        !!valueFormControl.valueRef.unresolved_rejection_comments_and_replies
      "
      class="comments-count"
      [unresolved_regular_comments_and_replies]="valueFormControl.valueRef.unresolved_regular_comments_and_replies"
      [unresolved_rejection_comments_and_replies]="valueFormControl.valueRef.unresolved_rejection_comments_and_replies"
      (actionSelected)="selectFieldForFocus(valueFormControl.valueRef, valueGroup, $event)"
    ></div>
    <div
      lib-field-actions
      data-testid="fieldActions"
      *ngIf="displayFieldActions"
      [valueDefinitionType]="valueFormControl.valueRef.type"
      [unresolvedRejectionCommentsAndReplies]="valueFormControl.valueRef.unresolved_rejection_comments_and_replies"
      [valueGroupSetStatus]="valueGroupSetStatus"
      [dataRequestSourceStatus]="dataRequestSourceStatus"
      [collaboratorResponsibility]="collaboratorResponsibility"
      (actionSelected)="selectFieldForFocus(valueFormControl.valueRef, valueGroup, $event)"
    ></div> -->
  </div>
</div>
