import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ValueFormControl } from '../../../metric-editor-form/models/valueFormControl';
import { FormUtils } from '../../../classes';
import {
  ConsolidationRules,
  MetricTableDefinition,
  SOURCE_CONFIGURATION,
  ValueDefinitionType,
  getConsolidatedInfo,
  isQuantitativeRule,
  isDBConsolidationEnabled,
  isFieldBypassConsolidation,
} from '../../../models';
import { TableTotalFormulaPipe } from '../../../metric-editor-form/pipes/table-total-formula/table-total-formula.pipe';
import { MetricTableTotalFormulaPipe } from '../../../pipes';

@Component({
  selector: 'lib-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
})
export class FormFieldLabelComponent implements OnInit, OnChanges {
  @Input({ required: true }) control!: UntypedFormControl | ValueFormControl;
  @Input() sourceConfiguration: SOURCE_CONFIGURATION = SOURCE_CONFIGURATION.dynamic_consolidation;
  @Input() id: string = '';
  @Input() for: string = '';
  @Input() label: string = '';
  @Input() displayReset: boolean = true;
  @Input() metricTableDefinition?: MetricTableDefinition;
  @Input() sameSizeLabels: boolean = false; // When using multiple fields in the same input

  required: boolean = false;
  fieldInfo: string = '';
  displayConsolidatedInfo: boolean = false;
  displayTableTotalInfo: boolean = false;
  tableTotalFormulaPipe = new TableTotalFormulaPipe();
  metricTableTotalFormulaPipe = new MetricTableTotalFormulaPipe();
  isBypassConsolidation: boolean = false;
  combinedClasses: any;

  constructor() {}

  ngOnInit(): void {
    this.combinedClasses = {
      'color-error': this.control.touched && this.control.invalid,
      required: this.required,
      disabled: this.control.disabled,
      'force-same-size-labels': this.sameSizeLabels,
    };
  }

  ngOnChanges(): void {
    this.required = this.control.hasValidator(Validators.required);
    this.setIsBypassConsolidation();
    this.setDisplayConsolidatedInfo();
    this.setDisplayTableCalculatedInfo();
    this.fieldInfo = this.setToolTipInfo();
  }

  setIsBypassConsolidation(): void {
    if (!(this.control instanceof ValueFormControl)) {
      this.isBypassConsolidation = false;
      return;
    }

    const businessUnitLevel = this.control.businessUnitLevel();
    this.isBypassConsolidation = isFieldBypassConsolidation(
      this.control.valueRef.bypass_consolidation_levels as number[] | null,
      businessUnitLevel,
    );
  }

  displayDeleteIcon(): boolean {
    if (!this.isValueFormControl(this.control) || !this.displayReset || this.control.disabled) {
      return false;
    }

    const hasValueAndId = this.hasValue() && !FormUtils.isNullOrEmpty(this.control.valueRef.id);

    if (this.control.isConsolidated()) {
      const isConsolidationInactive =
        !isQuantitativeRule(this.control.valueRef.consolidation_rule) || this.isBypassConsolidation;
      return hasValueAndId && isConsolidationInactive;
    }
    return hasValueAndId;
  }

  setToolTipInfo(): string {
    if (this.displayConsolidatedInfo) {
      return this.formatConsolidatedInfo();
    }
    if (this.displayTableTotalInfo) {
      return this.formatMetricTableTotalInfo();
    }
    return '';
  }

  isConsolidationEnabled(sourceConfiguration: SOURCE_CONFIGURATION): boolean {
    return isDBConsolidationEnabled(sourceConfiguration);
  }

  setDisplayConsolidatedInfo(): void {
    if (this.isValueFormControl(this.control)) {
      const isConsolidated = this.control.isConsolidated();
      let isCalculatedField = false;

      if (this.control instanceof ValueFormControl) {
        isCalculatedField = this.control.valueRef.type == ValueDefinitionType.calculated;
      }
      if (
        isConsolidated &&
        !isCalculatedField &&
        !this.isBypassConsolidation &&
        this.control.valueRef.consolidation_rule != ConsolidationRules.manual
      ) {
        this.displayConsolidatedInfo = true;
      }
    }
  }

  setDisplayTableCalculatedInfo(): void {
    if (this.isValueFormControl(this.control)) {
      let isMetricTable = false;

      if (this.metricTableDefinition) {
        isMetricTable = true;
      }
      if (isMetricTable) {
        this.displayTableTotalInfo = true;
      }
    }
  }

  formatMetricTableTotalInfo(): string {
    let calculatedTableInfo = '';
    if (this.control instanceof ValueFormControl && this.metricTableDefinition) {
      calculatedTableInfo = this.metricTableTotalFormulaPipe.transform(
        this.tableTotalFormulaPipe.transform(this.metricTableDefinition.calculation_definitions, this.control.valueRef),
      );
    }
    return calculatedTableInfo;
  }

  deleteValue(): void {
    if (this.isValueFormControl(this.control)) {
      this.control.resetValue();
    }
  }

  formatConsolidatedInfo(): string {
    let consolidatedInfo = '';
    if (this.control instanceof ValueFormControl) {
      consolidatedInfo = getConsolidatedInfo(this.control.valueRef);
    }
    return consolidatedInfo;
  }

  private hasValue(): boolean {
    const value = this.control.value as unknown;
    return Array.isArray(value) ? value.length > 0 : !!value || value === 0;
  }

  private isValueFormControl(control: UntypedFormControl): control is ValueFormControl {
    return 'valueRef' in control;
  }
}
