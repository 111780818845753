import { Entity } from '../entities';

export enum OptionListType {
  third_party_metrics = 'third_party_metrics',
  reference = 'reference',
  resource_list = 'resource_list',
}

export interface SelectionSetItemCDPDetails {
  cdp_option_id?: number;
  cdp_option_group_id?: number | null;
}

export interface OptionListItem extends Entity {
  active: boolean;
  display_explanation: boolean;
  explanation_required: boolean;
  position: number;
  option_list_id: string;
  selection_set_id: string; // NF-12149, remove and use `option_list_id` instead
  sp_data_dpd_value?: string;
  cdp_details?: SelectionSetItemCDPDetails;
}

export interface OptionList extends Entity {
  active: boolean;
  core_option_list_category_id?: string;
  description?: string;
  explanation_label?: string;
  is_category_default?: boolean;
  public: boolean;
  selection_set_category_id?: string;
  type?: OptionListType;
}

export interface ListOptionListItemBaseRequest {
  active?: boolean;
  order_by?: string;
  order_by_direction?: 'asc' | 'desc';
  page?: number;
  page_size?: number;
  search_term?: string;
}

export interface ListCoreOptionListItemRequest extends ListOptionListItemBaseRequest {}

export interface ListPlatformOptionListItemRequest extends ListOptionListItemBaseRequest {}

export type ListOptionListItemRequest = ListCoreOptionListItemRequest | ListPlatformOptionListItemRequest;

export interface ListOptionListBaseRequest {
  active?: boolean;
  order_by?: string;
  order_by_direction?: 'asc' | 'desc';
  page?: number;
  page_size?: number;
  search_term?: string;
}

export interface ListCoreOptionListRequest extends ListOptionListBaseRequest {
  selection_set_category_id?: string;
  type?: string;
}

export interface ListPlatformOptionListRequest extends ListOptionListBaseRequest {
  core_option_list_category_id?: string;
  is_category_default?: boolean;
}

export type ListOptionListRequest = ListCoreOptionListRequest | ListPlatformOptionListRequest;

export interface UpsertOptionListItemBaseRequest {
  active: boolean;
  display_explanation: boolean;
  explanation_required: boolean;
  name: string;
}

export interface UpsertCoreOptionListItemRequest extends UpsertOptionListItemBaseRequest {
  sp_data_dpd_value?: string;
  cdp_details?: SelectionSetItemCDPDetails;
}

export interface UpsertPlatformOptionListItemRequest extends UpsertOptionListItemBaseRequest {}

export type UpsertOptionListItemRequest = UpsertCoreOptionListItemRequest | UpsertPlatformOptionListItemRequest;

export interface BulkUpsertOptionListRequest {
  option_list_items: UpsertOptionListItemRequest[];
}

export interface UpsertOptionListBaseRequest {
  active: boolean;
  description?: string;
  explanation_label?: string;
  name: string;
}

export interface UpsertCoreOptionListRequest extends UpsertOptionListBaseRequest {
  public: boolean;
  selection_set_category_id: string;
  type: OptionListType;
}

export interface UpsertPlatformOptionListRequest extends UpsertOptionListBaseRequest {
  core_option_list_category_id?: string;
}

export type UpsertOptionListRequest = UpsertCoreOptionListRequest | UpsertPlatformOptionListRequest;
