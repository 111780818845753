import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  ActionItem,
  ChoiceFieldSource,
  ChoiceTypeDetails,
  Metric,
  MetricCategory,
  ValueDefinition,
} from '../../../../../../models';
import { map, takeUntil } from 'rxjs/operators';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DialogsService } from '../../../../../../dialogs';
import { provideComponentStore } from '@ngrx/component-store';
import {
  MetricStructureChoiceFieldSelectionState,
  MetricStructureChoiceFieldSelectionStore,
} from './metric-structure-choice-field-selection.store';
import { CustomSelectionListDialogComponent } from '../../../../custom-selection-list-dialog/custom-selection-list-dialog.component';
import {
  CustomSelectionListDialogConfig,
  CustomSelectionListDialogResult,
} from '../../../../custom-selection-list-dialog/models';
import { FeatureFlagService } from '../../../../../../feature-flag';
import { MetricStructureStateService } from '../../../../../services/metric-structure-state.service';
import { OptionListsApiService } from '../../../../../../services/api-services';
import { TranslateService } from '../../../../../../services/common';

@Component({
  selector: 'lib-metric-structure-choice-field-selection',
  templateUrl: './metric-structure-choice-field-selection.component.html',
  styleUrls: ['./metric-structure-choice-field-selection.component.scss'],
  providers: [provideComponentStore(MetricStructureChoiceFieldSelectionStore)],
})
export class MetricStructureChoiceFieldSelectionComponent implements OnInit, OnChanges {
  @Input() metric?: Metric;
  @Input() optionListFormControl?: UntypedFormControl;
  @Input({ required: true }) selectionListCategoryFormControl!: UntypedFormControl;
  @Input({ required: true }) selectionListFormControl!: UntypedFormControl;
  @Input({ required: true }) valueDefinition!: ValueDefinition<ChoiceTypeDetails>;
  @Input() canCreateCustomChoices: boolean = false;

  @Output() customChoiceAnswersChange: EventEmitter<ActionItem[]> = new EventEmitter<ActionItem[]>();

  public readonly eMetricCategory = MetricCategory;
  public readonly eChoiceFieldSource = ChoiceFieldSource;

  public data$?: Observable<MetricStructureChoiceFieldSelectionState>;
  public customChoiceAnswers: ActionItem[] = [];
  public hint: string | null = null;
  public isAdmin = false;
  public isLoading = true;
  public legacySelection = false;
  public legacyCoreSelecitonWithoutCategory = false;
  public settingsOptionListsEnabled = false;

  private selectionListCategoryFormControlSubscription?: Subscription;

  constructor(
    private readonly translateService: TranslateService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly dialogsService: DialogsService,
    private readonly metricStructureService: MetricStructureStateService,
    private readonly metricStructureChoiceFieldSelectionStore: MetricStructureChoiceFieldSelectionStore,
    private readonly optionListsApiService: OptionListsApiService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  public ngOnInit(): void {
    this.isAdmin = this.metricStructureService.isAdmin;
    this.settingsOptionListsEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'settings_option_lists_enabled',
    ]);
    const optionListsEnabled = this.isAdmin || this.settingsOptionListsEnabled;
    this.metricStructureChoiceFieldSelectionStore.initialize(optionListsEnabled, this.metric);
    this.data$ = this.metricStructureChoiceFieldSelectionStore.state$.pipe(
      map(({ optionLists, ...data }) => ({
        ...data,
        optionLists:
          this.canCreateCustomChoices && !this.settingsOptionListsEnabled
            ? [{ id: 'custom', title: this.translateService.instant('Custom') }, ...optionLists]
            : optionLists,
      })),
    );

    this.initializeCategory();
    this.initializeHint();
    this.initializeCustomChoices();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.valueDefinition?.currentValue &&
      changes.valueDefinition?.previousValue &&
      (changes.valueDefinition.currentValue.id !== changes.valueDefinition.previousValue.id ||
        (changes.valueDefinition.currentValue.id === changes.valueDefinition.previousValue.id &&
          changes.valueDefinition.currentValue.updated !== changes.valueDefinition.previousValue.updated))
    ) {
      this.isLoading = true;
      this.initializeCustomChoices();
      this.initializeCategory();
      this.initializeHint();
    } else if (
      changes.valueDefinition?.currentValue &&
      changes.valueDefinition?.previousValue &&
      changes.valueDefinition.currentValue.id === changes.valueDefinition.previousValue.id &&
      !this.selectionListCategoryFormControl.value
    ) {
      this.isLoading = true;
      this.initializeCategory();
    }
  }

  public redirectToOptionLists(): void {
    this.optionListsApiService.redirectToOptionLists();
  }

  public updateAnswers(): void {
    if (this.settingsOptionListsEnabled || this.isAdmin) {
      return;
    }

    if (this.selectionListFormControl.value === 'custom') {
      this.selectionListFormControl.setValidators([Validators.required, this.choicesValidator()]);
      this.selectionListFormControl.updateValueAndValidity();
    } else {
      this.optionListFormControl?.setValue(null);
      this.selectionListFormControl.setValidators(Validators.required);
      this.customChoiceAnswers = [];
    }
  }

  public choicesValidator =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (control.value === 'custom' && !this.customChoiceAnswers.length) {
        return { invalidChoices: true };
      }
      return null;
    };

  public launchCustomSelectionDialog(): void {
    const dialogRef = this.dialogsService.open<
      CustomSelectionListDialogComponent,
      CustomSelectionListDialogConfig,
      CustomSelectionListDialogResult
    >(CustomSelectionListDialogComponent, {
      data: {
        selectionSetId: this.selectionListFormControl.value,
        optionListId: this.optionListFormControl?.value,
        customChoiceAnswers: this.customChoiceAnswers,
      },
      viewContainerRef: this.viewContainerRef,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.choices) {
        this.optionListFormControl?.setValue(result.optionListId);
        this.selectionListFormControl.setValue('custom');
        this.selectionListFormControl.markAsDirty();
        this.customChoiceAnswers = result.choices ?? [];
        this.customChoiceAnswersChange.emit(this.customChoiceAnswers);
        this.updateAnswers();
      } else {
        this.selectionListFormControl.setValue(null);
      }
    });
  }

  private initializeHint(): void {
    if (this.isAdmin) {
      this.hint =
        this.metric?.category === MetricCategory.REFERENCE
          ? this.translateService.instant('Core defined option lists are not editable on Platform')
          : null;
    } else if (this.metric?.category === MetricCategory.REFERENCE) {
      if (this.valueDefinition.type_details?.selection_set_category_id) {
        this.hint = this.translateService.instant('Only option lists for the selected category are available.');
      } else if (
        this.valueDefinition.type_details?.selection_set_id &&
        this.valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.core
      ) {
        this.hint = this.translateService.instant('This option list is defined by Novisto and cannot be modified.');
      }
    } else if (this.metric?.category === MetricCategory.CUSTOM) {
      this.hint = this.translateService.instant('Only option lists for the selected category are available.');
    }
  }

  private initializeCustomChoices(): void {
    if (this.settingsOptionListsEnabled) {
      return;
    }

    if (this.selectionListFormControl.value === 'custom' && this.optionListFormControl?.value) {
      this.optionListsApiService
        .listOptionListItems(String(this.optionListFormControl.value), {
          order_by: 'position',
          order_by_direction: 'asc',
        })
        .subscribe((res) => {
          this.customChoiceAnswers = res.data.map((i) => ({ id: i.id, title: i.name }));
          this.updateAnswers();
        });
    } else {
      this.updateAnswers();
    }
  }

  private initializeCategory(): void {
    if (!this.isAdmin && !this.settingsOptionListsEnabled) {
      return;
    }

    this.selectionListCategoryFormControlSubscription?.unsubscribe();
    this.selectionListCategoryFormControlSubscription = this.selectionListCategoryFormControl.valueChanges
      .pipe(takeUntil(this.metricStructureChoiceFieldSelectionStore.destroy$))
      .subscribe((categoryId) => {
        if (!this.legacySelection) {
          this.selectionListFormControl.setValue(null);
        }

        this.metricStructureChoiceFieldSelectionStore.updateOptionListCategoryState(
          categoryId
            ? {
                categoryId: String(categoryId),
                source: this.valueDefinition.type_details?.selection_set_source,
              }
            : null,
        );
      });

    this.optionListsApiService.fetchOptionListCategory(this.valueDefinition).subscribe((categoryId) => {
      this.legacyCoreSelecitonWithoutCategory = Boolean(
        this.valueDefinition.type_details?.selection_set_id &&
          this.metric?.category === MetricCategory.REFERENCE &&
          !categoryId &&
          this.valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.core,
      );

      if (categoryId || this.legacyCoreSelecitonWithoutCategory) {
        this.selectionListCategoryFormControl.setValue(categoryId, { emitEvent: false });
        this.metricStructureChoiceFieldSelectionStore.updateOptionListCategoryState({
          categoryId,
          source: this.valueDefinition.type_details?.selection_set_source,
        });
      }

      this.legacySelection = Boolean(
        this.valueDefinition.type_details?.selection_set_id &&
          ((this.isAdmin && !categoryId) ||
            (this.valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.platform && !categoryId) ||
            (this.metric?.category === MetricCategory.CUSTOM &&
              this.valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.core)),
      );

      if (this.legacySelection) {
        this.selectionListFormControl.setValidators([]);
        this.selectionListCategoryFormControl.setValidators([]);
      }

      this.isLoading = false;
    });
  }
}
