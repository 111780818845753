<div *ngIf="data$ | async as data" class="content p-4">
  <h3>{{ "Resource List" | translate }}</h3>

  <ng-container *ngIf="!data.isLoading; else dummyTable">
    <lib-searchable-select-input
      [options]="data.resourceLists"
      [control]="form.controls.resourceList"
      [reachedEnd]="true"
      [bindOptionLabelFn]="bindOptionLabelFn"
      [compareFn]="compareFn"
    ></lib-searchable-select-input>

    <lib-multi-select-dropdown
      [control]="form.controls.resourceListItems"
      [options]="data.resourceListItems | objectValues"
      [withSelectAllOption]="true"
    >
    </lib-multi-select-dropdown>

    <button
      lib-button
      [buttonType]="'primary'"
      class="mt-3"
      [disabled]="!form.controls.resourceListItems.value.length"
      (click)="bulkAdd(data.resourceListItems)"
    >
      {{ "Add" | translate }}
    </button>
  </ng-container>
</div>

<ng-template #dummyTable>
  <lib-dummy [presentation]="ePresentation.card"></lib-dummy>
</ng-template>
