import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { delay, Observable, Subscription } from 'rxjs';

import {
  ActionItem,
  FiscalYear,
  Metric,
  MetricCategory,
  SOURCE_CONFIGURATION,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionGroup,
} from '../../../models';
import { MetricStructureSelectable, MetricStructureSelectableType, MetricTableGroup } from '../../models';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';
import { TranslateService } from '../../../services/common';
import { FeatureFlagService } from '../../../feature-flag';

@Component({
  selector: 'lib-metric-structure-properties-panel',
  templateUrl: './metric-structure-properties-panel.component.html',
  styleUrls: ['./metric-structure-properties-panel.component.scss'],
})
export class MetricStructurePropertiesPanelComponent implements OnInit, OnDestroy {
  @Input({ required: true }) metric!: Metric;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input() fiscalYears: FiscalYear[] = [];

  selectedItem?: MetricStructureSelectable;
  @Input() set metricStructureSelectable(metricStructureSelectable: MetricStructureSelectable | undefined) {
    this.selectedItem = metricStructureSelectable;
    this.resetSelectedPanelTab(metricStructureSelectable?.selectedTab);
    this.handleParentValueDefinitionGroup();
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  readonly panelTabActions: ActionItem[] = [
    {
      id: '0',
      title: '',
      icon: 'settings',
    },
    {
      id: '1',
      title: this.translateService.instant('Field Information'),
      icon: 'field-info',
    },
    {
      id: '2',
      title: this.translateService.instant('Conditional Triggers'),
      icon: 'condition',
    },
    {
      id: '3',
      title: this.translateService.instant('Table Totals'),
      icon: 'calendar',
    },
    {
      id: '4',
      title: this.translateService.instant('Repeatable Group Export Configuration'),
      icon: 'repeat',
    },
    {
      id: '5',
      title: this.translateService.instant('Frequency'),
      icon: 'repeat',
    },
  ];
  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly eValueDefinitionType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly eMetricStructureSelectableType: typeof MetricStructureSelectableType = MetricStructureSelectableType;

  isCreatingField$: Observable<boolean> = this.metricStructureService.isCreatingField$;
  isRepeatableGroup$: Observable<boolean> = this.metricStructureService.isRepeatableGroup$.pipe(delay(0));

  selectedPanelTabAction: ActionItem = this.panelTabActions[0];
  parentValueDefinitionGroup?: ValueDefinitionGroup;
  canEditEveryMetrics: boolean = false;
  isFieldInfoPanelEnabled: boolean = this.featureFlagService.areAnyFeatureFlagsEnabled([
    'mse_field_info_panel_enabled',
  ]);
  isAdmin: boolean = this.metricStructureService.isAdmin;

  private canEditEveryMetricsSubscription?: Subscription;

  get selectedItemType(): MetricStructureSelectableType | undefined {
    if (!this.selectedItem) {
      return undefined;
    }
    if ('value_definition_groups' in this.selectedItem) {
      return MetricStructureSelectableType.metric;
    }
    if ('value_definitions' in this.selectedItem) {
      return MetricStructureSelectableType.group;
    }
    if ((this.selectedItem as MetricTableGroup).table_id != null) {
      return MetricStructureSelectableType.table;
    }
    return MetricStructureSelectableType.valueDefinition;
  }

  constructor(
    private translateService: TranslateService,
    private metricStructureService: MetricStructureStateService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public ngOnInit(): void {
    this._resetPanelTabToSettings();
    this.canEditEveryMetricsSubscription = this.metricStructureService.canEditEveryMetrics$.subscribe(
      (canEditEveryMetrics) => {
        this.canEditEveryMetrics = canEditEveryMetrics;
      },
    );
  }
  public ngOnDestroy(): void {
    this.canEditEveryMetricsSubscription?.unsubscribe();
  }

  public closeProperties(): void {
    this.metricStructureService.updateSelectedItem(undefined);
    this.closePanel.emit();
  }

  public setSelectedPanelTab(option: ActionItem): void {
    this.selectedPanelTabAction = option;
    this.metricStructureService.updateSelectedItem(this.selectedItem, +option.id);
  }

  public resetSelectedPanelTab(selectedTab: number = 0): void {
    this.selectedPanelTabAction = this.panelTabActions[selectedTab];
  }

  public updateMetricAndKeepSelectedTab(metric: Metric): void {
    const selectedItem = this.selectedItem ? ({ ...this.selectedItem } as MetricStructureSelectable) : undefined;
    this.metricStructureService.updateMetric(metric);

    if (selectedItem) {
      this.metricStructureService.updateSelectedItem(selectedItem, selectedItem.selectedTab);
    }
  }

  private handleParentValueDefinitionGroup(): void {
    if (this.selectedItemType === MetricStructureSelectableType.valueDefinition) {
      this.parentValueDefinitionGroup = this.metric.value_definition_groups?.find(
        (vdg) => vdg.id === (this.selectedItem as ValueDefinition).value_definition_group_id,
      );
    } else {
      this.parentValueDefinitionGroup = undefined;
    }
  }

  private _resetPanelTabToSettings(): void {
    this.metricStructureService.metric$.subscribe((res) => {
      if (res && res.value_definition_groups && !res.value_definition_groups[0]?.value_definitions?.length) {
        this.metricStructureSelectable = res;
      }
    });
  }
}
