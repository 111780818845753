<div class="panel-frequency-header mh-4">
  <div class="panel-properties-content-header-title fx-row justify-space-between w-100">
    <div class="fx-row justify-center align-center">
      <mat-icon [svgIcon]="panelTabAction?.icon ?? ''" class="mr-2"></mat-icon>
      <h4 class="mat-subtitle-2">{{ panelTabAction?.title }}</h4>
    </div>
  </div>
</div>

<div *ngIf="metricStructureFieldFrequencyForm" class="panel-frequency-container mh-4">
  <form class="panel-frequency-form">
    <div class="year-to-date-section" *ngIf="metricStructureFieldFrequencyForm.controls.yearToDateCalculation">
      <lib-info-banner
        type="info"
        [message]="
          'The year-to-date calculation selection for this field becomes permanent after you click Save.' | translate
        "
      ></lib-info-banner>

      <lib-radio-buttons
        [label]="'Year-to-date calculation' | translate"
        [control]="metricStructureFieldFrequencyForm.controls.yearToDateCalculation"
        [column]="true"
        [choices]="yearToDateCalculationOptions"
      ></lib-radio-buttons>
    </div>

    <div class="year-to-date-section form">
      <lib-info-banner
        [type]="metricStructureFieldFrequencyForm.isInvalidFrequencyState ? 'error' : 'info'"
        [message]="'The frequency can only increase over time.' | translate"
        data-testid="frequency-warning-banner"
      ></lib-info-banner>

      <lib-select-input
        *ngFor="let frequencyFormControl of metricStructureFieldFrequencyForm.sortedAscFrequencyControls"
        [label]="
          'Fiscal year {frequencyCode}'
            | translate: { frequencyCode: frequencyFormControl.controls.frequency.frequencyCode }
        "
        [control]="frequencyFormControl.controls.frequency"
        [options]="frequencyItemOptions"
        [matTooltip]="
          frequencyFormControl.controls.frequency.disabled ? ('Data submitted for this fiscal year' | translate) : ''
        "
        matTooltipPosition="above"
        class="w-100"
      >
      </lib-select-input>
    </div>
  </form>

  <div class="panel-frequency-footer">
    <lib-divider class="pb-4"></lib-divider>

    <div class="form-submit">
      <button
        lib-button
        buttonType="primary"
        [label]="'Save' | translate"
        [disabled]="metricStructureFieldFrequencyForm.pristine || metricStructureFieldFrequencyForm.invalid"
        (click)="submitForm()"
        [isLoading]="loading"
        class="mr-4"
      ></button>
      <button lib-button buttonType="link" (click)="closePanel.emit()">{{ "Cancel" | translate }}</button>
    </div>
  </div>
</div>
