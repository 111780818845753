import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription, filter, switchMap, take } from 'rxjs';
import { OptionListCategoryDefaultItem, OptionListCategoryDefaultState, OptionListsStore } from '../option-lists.store';
import { OptionList } from '../../models';
import { OptionListsApiService } from '../../services/api-services';
import { DEFAULT_PAGE_SIZE } from '../../data-table';

@Component({
  selector: 'lib-option-list-category-defaults',
  templateUrl: './option-list-category-defaults.component.html',
  styleUrls: ['./option-list-category-defaults.component.scss'],
})
export class OptionListCategoryDefaultsComponent implements OnInit, OnDestroy {
  public data$?: Observable<OptionListCategoryDefaultState>;
  public missingItems: OptionListCategoryDefaultItem[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly optionListsApiService: OptionListsApiService,
    private readonly optionListsStore: OptionListsStore,
  ) {}

  public ngOnInit(): void {
    this.data$ = this.optionListsStore.optionListCategoryDefault$;
    this.optionListsStore.optionListCategoryDefault$
      .pipe(
        filter((optionListCategoryDefault) => Boolean(Object.values(optionListCategoryDefault).length)),
        take(1),
      )
      .subscribe((optionListCategoryDefault) => {
        this.missingItems = Object.values(optionListCategoryDefault).filter((i) => !i.control.value);
        Object.values(optionListCategoryDefault).forEach((item) => {
          this.listenItemValueChanges(item);
        });
      });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public bindOptionListCategoryDefaultItemLabelFn(item: OptionListCategoryDefaultItem): string {
    return item.category.name;
  }

  public bindOptionLabelFn(optionList: OptionList): string {
    return optionList.name;
  }

  public compareFn(optionListA?: OptionList, optionListB?: OptionList): boolean {
    return Boolean(optionListA && optionListB && optionListA.id === optionListB.id);
  }

  public loadOptionLists(item: OptionListCategoryDefaultItem, from: number = 0, searchTerm: string = ''): void {
    const page = from ? Math.round(from / DEFAULT_PAGE_SIZE) + 1 : 1;

    if (!item.isLoading && (!item.reachedEnd || page === 1)) {
      this.optionListsStore.updateOptionListCategoryDefaultState({ ...item, isLoading: true });

      this.optionListsApiService
        .listOptionLists({
          core_option_list_category_id: item.category.id,
          page_size: DEFAULT_PAGE_SIZE,
          page,
          search_term: searchTerm,
          order_by: 'name',
          order_by_direction: 'asc',
        })
        .subscribe((res) => {
          const total = res.meta.total_count || res.data.length;
          const options = page === 1 ? res.data : [...item.options, ...res.data];
          this.optionListsStore.updateOptionListCategoryDefaultState({
            ...item,
            searchTerm,
            isInitialized: true,
            isLoading: false,
            options,
            reachedEnd: options.length >= total,
          });
        });
    }
  }

  public openedChange(open: boolean, item: OptionListCategoryDefaultItem): void {
    const optionList = item.options.find((i) => i.id === item.control.value?.id);

    if (open && !item.isInitialized) {
      this.loadOptionLists(item);
    } else if (open && optionList && !optionList.name.toLowerCase().includes(item.searchTerm.toLowerCase())) {
      this.optionListsStore.updateOptionListCategoryDefaultState({
        ...item,
        options: item.options.filter((i) => i.id !== optionList.id),
      });
    } else if (!open && item.control.value && !optionList) {
      this.optionListsStore.updateOptionListCategoryDefaultState({
        ...item,
        options: [...item.options, item.control.value],
      });
    }
  }

  public trackBy = (_i: number, item: OptionListCategoryDefaultItem): string => item.category.id;

  private listenItemValueChanges(item: OptionListCategoryDefaultItem): void {
    const subscription = item.control.valueChanges
      .pipe(
        filter((optionList): optionList is OptionList => Boolean(optionList)),
        switchMap((optionList) =>
          this.optionListsApiService.makeOptionListCategoryDefault(optionList.id, item.category.id),
        ),
      )
      .subscribe(() => {
        this.missingItems = this.missingItems.filter((i) => i.category.id !== item.category.id);
      });

    this.subscriptions.push(subscription);
  }
}
