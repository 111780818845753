import { MetricUtils } from '../../../classes/MetricUtils/metric-utils';
import { Metric, isValueDefinition, isValueDefinitionGroup } from '../../../models';
import { MetricStructureSelectable } from '../../models';

export class MetricStructureUtils {
  public static isEntityFromCoreRefV2Metric(
    metricStructureSelectable?: MetricStructureSelectable,
    metric?: Metric,
  ): boolean {
    if (metric && MetricUtils.isRefV2Metric(metric)) {
      return (
        (isValueDefinition(metricStructureSelectable) && Boolean(metricStructureSelectable.core_value_definition_id)) ||
        (isValueDefinitionGroup(metricStructureSelectable) &&
          Boolean(metricStructureSelectable.core_value_definition_group_id))
      );
    }
    return false;
  }
}
