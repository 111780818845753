<ng-container *ngIf="data$ | async as data">
  <lib-page-context-bar [pageName]="data.optionList.name" [withSeparator]="true">
    <ng-container main-actions>
      <button lib-button buttonType="cancel_with_border" (click)="cancel.emit()">
        {{ "Cancel" | translate }}
      </button>

      <button lib-button (click)="setOptionListSidebarVisible(true, data.optionList)">
        <mat-icon class="icon-lg" svgIcon="settings"></mat-icon>
      </button>
    </ng-container>

    <span title-extra-info class="caption4">
      <span *ngIf="data.optionList.type">{{ eOptionListTypeTranslations[data.optionList.type] }} / </span>
      {{ data.optionListCategory?.name }}
    </span>
  </lib-page-context-bar>

  <div class="fx-row h-100">
    <lib-option-list-resource-lists
      *ngIf="withResourceLists"
      [optionList]="data.optionList"
    ></lib-option-list-resource-lists>

    <div class="w-100">
      <lib-page-context-bar [withSeparator]="true">
        <ng-container *ngIf="canManageOptionLists" main-actions>
          <button lib-button buttonType="primary" (click)="setOptionListItemSidebarVisible(true)">
            + {{ "Add option" | translate }}
          </button>
        </ng-container>

        <div title-extra-info>
          <h3 class="mt-4 sub-header">{{ "Options" | translate }}</h3>
          <span class="caption4">
            {{ "Click" | translate }} <b>{{ "Add option" | translate }}</b>
            {{ "to add an item to this list" | translate }}
          </span>
        </div>
      </lib-page-context-bar>

      <lib-filter-bar
        *ngIf="data.tableFilters.length"
        [secondaryFilters]="data.tableFilters"
        [withSearchBar]="true"
        [searchBarPlaceholder]="'Type to search' | translate"
        (onSearchChanged)="onSearchChange($event)"
        (onFilterChanged)="onFilterChange($event)"
      ></lib-filter-bar>

      <div *ngIf="data.isLoading || data.total; else noOptions" class="p-5">
        <lib-table-grid
          *ngIf="!data.isLoading; else dummyTable"
          [columns]="optionListItemTableColumns"
          [values]="data.optionListItems"
          [totalItemCount]="data.total"
          [isPaginable]="true"
          [isRowReorderable]="true"
          [actionMenuItems]="canManageOptionLists ? optionListOptionActionMenu : undefined"
          (pageChanged)="onPageChange($event)"
          (rowOrderChanged)="onOrderChange(data.optionListItems[$event.newIndex], $event.newIndex + 1)"
          (rowSelected)="canManageOptionLists ? setOptionListItemSidebarVisible(true, $event) : undefined"
        >
        </lib-table-grid>
      </div>
    </div>
  </div>

  <ng-template #dummyTable>
    <lib-dummy [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
  </ng-template>

  <lib-sidebar
    *ngIf="optionListForm"
    [secondaryBtn]="'Cancel' | translate"
    [primaryBtn]="'Update' | translate"
    [primaryBtnDisabled]="!optionListForm.valid || !optionListForm.touched"
    [title]="'Edit {name}' | translate: { name: data.optionList.name }"
    [visible]="isOptionListSidebarVisible"
    (secondaryClick)="setOptionListSidebarVisible(false, data.optionList)"
    (primaryClick)="updateOptionList(data.optionList)"
    (visibleChange)="setOptionListSidebarVisible($event, data.optionList)"
  >
    <div class="p-4">
      <lib-option-list-form
        [form]="optionListForm"
        [optionListCategories]="data.optionListCategories"
        [withPublicField]="withPublicField"
        [withTypeField]="withTypeField"
      ></lib-option-list-form>
    </div>
  </lib-sidebar>

  <lib-sidebar
    *ngIf="optionListItemForm"
    [secondaryBtn]="'Cancel' | translate"
    [primaryBtn]="(optionListItemForm.controls.optionListItemId.value ? 'Update' : 'Add') | translate"
    [primaryBtnDisabled]="!optionListItemForm.valid || !optionListItemForm.touched"
    [title]="(optionListItemForm.controls.optionListItemId.value ? 'Edit option' : 'Add to option list') | translate"
    [visible]="isOptionListItemSidebarVisible"
    (secondaryClick)="setOptionListItemSidebarVisible(false)"
    (primaryClick)="saveOptionListItem(data.optionList)"
    (visibleChange)="setOptionListItemSidebarVisible($event)"
  >
    <div class="p-4">
      <lib-option-list-item-form
        *ngIf="isOptionListItemSidebarVisible"
        [form]="optionListItemForm"
        [withTagFields]="withTagFields"
      ></lib-option-list-item-form>
    </div>
  </lib-sidebar>

  <ng-template #noOptions>
    <div class="fx-row justify-center mt-6">
      <lib-info-notice
        *ngIf="!data.filtered; else empty"
        icon="error"
        [primaryMessage]="'Almost there! We need to add some options.' | translate"
        [secondaryMessage]="
          (withResourceLists
            ? 'Please select a resource list to get started.'
            : 'Please select add option to get started.'
          ) | translate
        "
        type="error"
      ></lib-info-notice>

      <ng-template #empty>
        <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
      </ng-template>
    </div>
  </ng-template>
</ng-container>

<ng-template #booleanCell let-element>
  <span>{{ (element ? "Yes" : "No") | translate }}</span>
</ng-template>

<ng-template #statusCell let-element>
  <lib-tag
    [value]="(element ? 'Active' : 'Inactive') | translate"
    [tagType]="element ? eTagType.INFO : eTagType.GREY"
  ></lib-tag>
</ng-template>
