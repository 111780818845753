import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { combineLatest, filter, finalize, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { provideComponentStore } from '@ngrx/component-store';

import {
  ActionItem,
  ApiResponse,
  ConfirmationDialogConfig,
  DialogSize,
  FieldInformationRequest,
  Indicator,
  isValueDefinition,
  LimitedUser,
  Metric,
  MetricCategory,
  MetricTableDefinition,
  Presentation,
  READONLY_HELPER_TEXTS_VALUE_DEFINITION_TYPES,
  RelatedField,
  Status,
  Taxonomy,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../../../models';
import { AdminUsersService } from '../../../../services/admin';
import { FieldInformationForm } from './forms/field-information-form';
import { FieldInformationStore, SelectedItem } from './metric-structure-field-information.store';
import { IsTablePipe } from '../../../pipe/is-table/is-table.pipe';
import { MetricApiService } from '../../../../services/types';
import { MetricStructureSelectable, MetricTableGroup } from '../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import {
  AddRelatedFieldDialogComponent,
  AddRelatedFieldDialogComponentConfig,
  AddRelatedFieldDialogComponentResult,
} from './add-related-field-dialog/add-related-field-dialog.component';
import { ExpandableCardComponent } from '../../../../components';
import { TranslateService } from '../../../../services/common';
import {
  AddTaxonomiesDialogComponent,
  AddTaxonomiesDialogComponentConfig,
  AddTaxonomiesDialogComponentResult,
} from '../../add-taxonomies-dialog/add-taxonomies-dialog.component';
import { TaxonomiesCardComponent } from '../../taxonomies-card/taxonomies-card.component';
import sortBy from 'lodash/sortBy';
import { FeatureFlagService } from '../../../../feature-flag';

interface DetailsInfo {
  id: string;
  coreVdId?: string;
  fieldPosition?: number;
  published?: string;
  published_by?: string;
}

interface Data {
  relatedFields: RelatedField[];
  selectedItem: SelectedItem | undefined;
  taxonomies: Taxonomy[];
}

@Component({
  selector: 'lib-metric-structure-field-information',
  templateUrl: './metric-structure-field-information.component.html',
  styleUrls: ['./metric-structure-field-information.component.scss'],
  providers: [IsTablePipe, provideComponentStore(FieldInformationStore)],
})
export class MetricStructureFieldInformationComponent implements OnInit, OnDestroy {
  public readonly ePresentation = Presentation;
  public readonly eMetricCategory = MetricCategory;
  public readonly expandableCardMaxContentHeight = 250;

  @ViewChild(ExpandableCardComponent) relatedFieldsCard?: ExpandableCardComponent;
  @ViewChild(TaxonomiesCardComponent) taxonomiesCard?: TaxonomiesCardComponent;

  @Input({ required: true }) metric!: Metric;
  @Input() panelTabAction?: ActionItem;
  @Output() refresh: EventEmitter<Indicator> = new EventEmitter<Indicator>();
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  valueDefinitionGroup?: ValueDefinitionGroup;
  detailsInfo?: DetailsInfo;
  public users$?: Observable<LimitedUser[]>;
  public updating$: Observable<boolean> = this.metricStructureStateService.isMetricUpdating$;
  public tableDefinition$: Observable<MetricTableDefinition | undefined> = this.fieldInformationStore.tableDefinition$;

  public isAdmin: boolean = this.metricStructureStateService.isAdmin;

  public fieldInformationForm?: FieldInformationForm;

  public enableRefMetricsV2: boolean = false;
  public showTechnicalProtocolForm: boolean = true;

  public data$?: Observable<Data>;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminUsersService: AdminUsersService,
    private featureFlagService: FeatureFlagService,
    private dialogsService: DialogsService,
    private metricStructureStateService: MetricStructureStateService,
    private metricsService: MetricApiService,
    private isTablePipe: IsTablePipe,
    private fieldInformationStore: FieldInformationStore,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.enableRefMetricsV2 = this.featureFlagService.areAnyFeatureFlagsEnabled(['enable_ref_metrics_v2']);
    this.metricStructureStateService.selectedItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedItem: MetricStructureSelectable | undefined) => {
        this.fieldInformationStore.updateSelectedItem({ metric: this.metric, item: selectedItem });
        this.initDetailsInfo(selectedItem);
        this.initFieldInformationForm(selectedItem);
      });

    this.data$ = combineLatest([
      this.fieldInformationStore.selectedItem$,
      this.fieldInformationStore.relatedFields$,
      this.fieldInformationStore.taxonomies$,
    ]).pipe(
      map(([selectedItem, relatedFields, taxonomies]) => ({
        selectedItem,
        relatedFields: relatedFields.filter((relatedField) => this.isAdmin || !!relatedField.core_field_relation_id),
        taxonomies,
      })),
    );

    if (this.isAdmin) {
      this.users$ = this.adminUsersService.listLimitedUsers().pipe(map((res) => res.data));
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public addRelatedField(selectedItem: MetricStructureSelectable, relatedFields: RelatedField[]): void {
    this.dialogsService
      .open<AddRelatedFieldDialogComponent, AddRelatedFieldDialogComponentConfig, AddRelatedFieldDialogComponentResult>(
        AddRelatedFieldDialogComponent,
        {
          data: {
            id: selectedItem.id,
            metric_id: this.metric.id,
            relatedFields,
            size: DialogSize.full,
            value_definition_group_id: isValueDefinition(selectedItem)
              ? selectedItem.value_definition_group_id
              : undefined,
          },
          viewContainerRef: this.viewContainerRef,
        },
      )
      .afterClosed()
      .pipe(filter((dialogResult) => dialogResult?.status === Status.SUCCESS))
      .subscribe((dialogResult) => {
        if (dialogResult?.data) {
          this.fieldInformationStore.updateRelatedFields([...relatedFields, ...dialogResult.data.relatedFields]);
          this.relatedFieldsCard?.isExpanded && this.relatedFieldsCard.toggleExpand();
        }
      });
  }

  public addTaxonomies(selectedItem: MetricStructureSelectable, taxonomies: Taxonomy[]): void {
    this.dialogsService
      .open<AddTaxonomiesDialogComponent, AddTaxonomiesDialogComponentConfig, AddTaxonomiesDialogComponentResult>(
        AddTaxonomiesDialogComponent,
        {
          data: {
            valueDefinitionOrTableId: selectedItem.id,
            metric_id: this.metric.id,
            taxonomies,
            size: DialogSize.full,
            value_definition_group_id: isValueDefinition(selectedItem)
              ? selectedItem.value_definition_group_id
              : undefined,
          },
          viewContainerRef: this.viewContainerRef,
        },
      )
      .afterClosed()
      .pipe(filter((dialogResult) => dialogResult?.status === Status.SUCCESS))
      .subscribe((dialogResult) => {
        if (dialogResult?.data) {
          this.fieldInformationStore.updateTaxonomies(
            sortBy([...taxonomies, ...dialogResult.data.taxonomies], FieldInformationStore.TAXONOMY_ORDER_BY),
          );
          this.taxonomiesCard?.expandableCard?.isExpanded && this.taxonomiesCard.expandableCard.toggleExpand();
        }
      });
  }

  public closeTab(): void {
    this.closePanel.emit();
  }

  public deleteRelatedField(
    selectedItem: MetricStructureSelectable,
    relatedFields: RelatedField[],
    relatedField: RelatedField,
  ): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          primaryBtn: this.translateService.instant('Remove'),
          title: this.translateService.instant('Remove Novisto related field'),
          warningMsg: this.translateService.instant('Are you sure you wish to remove this related field "{label}"?', {
            label: relatedField.to_value_definition?.label || relatedField.to_metric_table_definition?.title,
          }),
        },
      })
      .afterClosed()
      .pipe(
        filter((dialogResult) => dialogResult?.status === Status.CONFIRMED),
        switchMap(() => {
          let obs;

          if (isValueDefinition(selectedItem)) {
            obs = this.metricsService.deleteFieldRelatedFields(
              this.metric.id,
              selectedItem.value_definition_group_id,
              selectedItem.id,
              relatedField.id,
            );
          } else {
            obs = this.metricsService.deleteMetricTableRelatedFields(this.metric.id, selectedItem.id, relatedField.id);
          }

          return obs;
        }),
      )
      .subscribe(() => {
        this.fieldInformationStore.updateRelatedFields(relatedFields.filter((rf) => rf.id !== relatedField.id));
      });
  }

  public deleteTaxonomyAssociation(
    selectedItem: MetricStructureSelectable,
    taxonomies: Taxonomy[],
    taxonomy: Taxonomy,
  ): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          primaryBtn: this.translateService.instant('Remove'),
          title: this.translateService.instant('Remove Novisto framework taxonomy'),
          warningMsg: this.translateService.instant(
            'Are you sure you wish to remove this framework taxonomy "{code}"?',
            { code: taxonomy.code },
          ),
        },
      })
      .afterClosed()
      .pipe(
        filter((dialogResult) => dialogResult?.status === Status.CONFIRMED),
        switchMap(() => {
          let obs;

          if (isValueDefinition(selectedItem)) {
            obs = this.metricsService.deleteFieldTaxonomy(
              this.metric.id,
              selectedItem.value_definition_group_id,
              selectedItem.id,
              taxonomy.id,
            );
          } else {
            obs = this.metricsService.deleteMetricTableTaxonomy(this.metric.id, selectedItem.id, taxonomy.id);
          }

          return obs;
        }),
      )
      .subscribe(() => {
        this.fieldInformationStore.updateTaxonomies(taxonomies.filter((t) => t.id !== taxonomy.id));
      });
  }

  public publishField(selectedItem: MetricStructureSelectable): void {
    if (this.isAdmin && isValueDefinition(selectedItem)) {
      const valueDefinitionGroupId: string = selectedItem.value_definition_group_id;
      const valueDefinitionId: string = selectedItem.id;
      this.metricsService
        .publishField(this.metric.id, valueDefinitionGroupId, valueDefinitionId)
        .subscribe((result: ApiResponse<Metric>) => {
          this.metricStructureStateService.updateMetric(result.data);
          const valueDefinition: ValueDefinition | undefined = result.data.value_definition_groups
            ?.find((vdg: ValueDefinitionGroup) => vdg.id === valueDefinitionGroupId)
            ?.value_definitions?.find((vd: ValueDefinition) => vd.id === valueDefinitionId);

          if (this.detailsInfo && valueDefinition) {
            this.detailsInfo.published = valueDefinition.published;
            this.detailsInfo.published_by = valueDefinition.published_by;
            this.metricStructureStateService.updateSelectedItem(valueDefinition);
          }
        });
    }
  }

  public saveProperties(selectedItem: MetricStructureSelectable): void {
    if (this.fieldInformationForm?.valid) {
      const payload: FieldInformationRequest = this.fieldInformationForm.toModel();
      const metricStructureSelectable: MetricTableGroup | ValueDefinition = <ValueDefinition | MetricTableGroup>{
        ...selectedItem,
      };

      this.metricStructureStateService.updateIsMetricUpdating(true);
      if (this.isTablePipe.transform(metricStructureSelectable)) {
        this.metricsService
          .updateMetricTableInformation(this.metric.id, metricStructureSelectable.table_id, payload)
          .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
          .subscribe((result: ApiResponse<Metric>) => {
            this.metricStructureStateService.updateMetric(result.data);
            this.fieldInformationForm?.markAsPristine();
          });
      } else {
        this.metricsService
          .updateFieldInformation(
            this.metric.id,
            metricStructureSelectable.value_definition_group_id,
            metricStructureSelectable.id,
            payload,
          )
          .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
          .subscribe((result: ApiResponse<Metric>) => {
            this.metricStructureStateService.updateMetric(result.data);
            this.fieldInformationForm?.markAsPristine();
          });
      }
    }
  }

  private initFieldInformationForm(selectedItem: MetricStructureSelectable | undefined): void {
    this.initFieldsConditions(selectedItem);

    if (selectedItem) {
      if ('table_id' in selectedItem) {
        this.tableDefinition$.subscribe((tableDefinition: MetricTableDefinition | undefined) => {
          this.fieldInformationForm = new FieldInformationForm(
            this.metric.reference_v2 ? tableDefinition?.technical_protocol : null,
            this.showTechnicalProtocolForm,
          );
        });
      } else {
        this.fieldInformationForm = new FieldInformationForm(
          this.metric.reference_v2 && 'technical_protocol' in selectedItem ? selectedItem.technical_protocol : null,
          this.showTechnicalProtocolForm,
        );
      }
    }
  }

  private initFieldsConditions(selectedItem: MetricStructureSelectable | undefined): void {
    const isReadonlyField: boolean = isValueDefinition(selectedItem)
      ? READONLY_HELPER_TEXTS_VALUE_DEFINITION_TYPES.includes(selectedItem.type)
      : false;
    const isCalculatedField: boolean =
      isValueDefinition(selectedItem) && selectedItem.type === ValueDefinitionType.calculated;

    if (isReadonlyField || isCalculatedField || this.isAdmin) {
      this.showTechnicalProtocolForm = false;
    }
  }

  private initDetailsInfo(selectedItem: MetricStructureSelectable | undefined): void {
    if (isValueDefinition(selectedItem)) {
      this.detailsInfo = {
        id: selectedItem.id,
        coreVdId: selectedItem.core_value_definition_id,
        fieldPosition: selectedItem.position,
        published: selectedItem.published,
        published_by: selectedItem.published_by,
      };
    } else {
      this.detailsInfo = { id: selectedItem?.id ?? '' };
    }
  }
}
