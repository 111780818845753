export const SP_JOB_IN_PROGRESS_ERROR: string = 'sp_job_in_progress_error';
export const CDP_JOB_IN_PROGRESS_ERROR: string = 'cdp_job_in_progress_error';
export const CDP_INVALID_CONFIGURATION_ERROR: string = 'cdp_invalid_configuration_error';
export const CDP_INVALID_TOKEN_ERROR: string = 'cdp_invalid_token_error';
export const CDP_RESOURCE_NOT_FOUND_ERROR: string = 'cdp_resource_not_found_error';
export const CDP_UNAUTHORIZED_ERROR: string = 'cdp_unauthorized_error';
export const CDP_GENERAL_ERROR: string = 'cdp_general_error';
export const PASSWORD_REUSE: string = 'password_reuse';
export const DATA_REQUEST_ALREADY_IMPORTED_ERROR: string = 'data_request_already_imported';
export const DATA_REQUEST_ARCHIVED_ERROR: string = 'invalid_data_request_archived';
export const MINIMUM_VALUE_DEFINITION_VALIDATION_ERROR: string = 'no_value_definition_matching_mapping';
export const DATA_REQUEST_COLLABORATION_INVALID_STATUS: string = 'invalid_status_data_request_collab';
export const CONVERT_BUSINESS_UNIT_WITH_DATA_ERROR: string = 'convert_business_unit_with_data_error';
export const CONVERT_BUSINESS_UNIT_ON_LEVEL_FOUR_ERROR: string = 'convert_business_unit_on_level_four_error';
export const CONVERT_LAST_CONSOLIDATED_BUSINESS_UNIT_ERROR: string = 'convert_last_consolidated_business_unit_error';
export const CONVERT_CONSOLIDATED_BUSINESS_UNIT_WITH_CHILDREN_ERROR: string =
  'convert_consolidated_business_unit_with_children_error';
export const REPORT_VALIDATION_ERROR: string = 'report_validation_error';
