import { Component } from '@angular/core';
import { ActionItem, Doc, EmptyResults, ItemType, Layout, Presentation, Status } from '../../../models';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '../../../services/common';

export interface LinkDocumentDialogData {
  doc: Doc;
}

@Component({
  selector: 'lib-link-document-dialog',
  templateUrl: './link-document-dialog.component.html',
  styleUrls: ['./link-document-dialog.component.scss'],
})
export class LinkDocumentDialogComponent {
  searchProperties = { search_type: 'name' };
  selectedDoc?: Doc;
  layout: Layout = Layout.grid;
  presentation: Presentation = Presentation.doc;

  readonly emptyResults: EmptyResults = {
    title: this.translateService.instant('No documents'),
    subtitle: '',
    image: 'laptop-neutral',
  };
  readonly itemTypeDoc: ItemType = ItemType.docs_doc;

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<LinkDocumentDialogComponent>,
  ) {}

  public handleSearchAction(action: ActionItem): void {
    this.selectedDoc = action.item.item;
  }

  public linkDocument(): void {
    this.dialogRef.close({ data: { doc: this.selectedDoc }, status: Status.SUCCESS });
  }

  public close(): void {
    this.dialogRef.close();
  }
}
