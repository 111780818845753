import { MetricTableGroup } from '../../metric-structure/models';
import { BaseValue, ValueDefinitionGroup, ValueDefinitionType, ValueGroup } from '../../models';

export class MetricTableUtils {
  public static mergeTableVDGIntoMetricTableGroup(
    tableValueDefinitionGroups: ValueDefinitionGroup[],
  ): MetricTableGroup | undefined {
    if (tableValueDefinitionGroups.length < 1) {
      return undefined;
    }

    return {
      id: String(tableValueDefinitionGroups[0].table_id),
      title: tableValueDefinitionGroups[0].label ?? '',
      metric_id: tableValueDefinitionGroups[0].metric_id,
      table_id: String(tableValueDefinitionGroups[0].table_id),
      position: tableValueDefinitionGroups[0].position,
      valueDefinitionGroups: tableValueDefinitionGroups,
      active: tableValueDefinitionGroups[0].active ?? true,
      end_fiscal_year_frequency_code: tableValueDefinitionGroups[0].end_fiscal_year_frequency_code ?? '',
    };
  }

  public static getMetricTableCellUniqueIdentifier(value: BaseValue, valueGroup: ValueGroup): string {
    const contextColumns = valueGroup.values?.filter((value) => value.type === ValueDefinitionType.label) ?? [];
    return contextColumns
      .map((cc) => `${cc.label ?? ''}:${cc.type_details.value ?? ''}`)
      .join('+')
      .concat(`|${value.label ?? ''}`);
  }
}
