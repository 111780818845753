import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, CacheService } from '../../common';
import {
  ApiResponse,
  FiscalYear,
  ApplicationApiDefinition,
  FiscalYearsFilteringOptions,
  FiscalYearRequest,
  FiscalYearClosingValidation,
  FiscalYearNotesRequest,
  FiscalYearCreateRegularRequest,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientFiscalYearsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  skipPaginationOptions = { limit: 0, offset: 0 };

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.fiscalYears;
  }

  public listFiscalYears(filteringOptions?: FiscalYearsFilteringOptions): Observable<ApiResponse<FiscalYear[]>> {
    return this.apiService
      .get(`${this.servicePath}${this.resource}/fiscal_years`, {
        params: filteringOptions ?? this.skipPaginationOptions,
      })
      .pipe(
        map((response) => {
          response.data = response.data.reduce((acc: FiscalYear[], value: any) => {
            const existingFY = acc.find((fy: FiscalYear) => fy.year === value.frequency_code);
            if (!existingFY) {
              return [
                ...acc,
                {
                  id: value.id,
                  year: value.frequency_code,
                  start: value.start,
                  end: value.end,
                  unit_id: value.business_unit_id,
                  status: value.status,
                  framework_taxonomy_status: value.framework_taxonomy_status,
                },
              ];
            }
            return acc;
          }, []);

          return response;
        }),
      );
  }

  public getFiscalYear(id: string): Observable<ApiResponse<FiscalYear>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/fiscal_years/${id}`);
  }

  public getFiscalYearByYear(year: number): Observable<ApiResponse<FiscalYear[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/fiscal_years/end/${year}`);
  }

  public createIrregularFiscalYear(fiscalYear: FiscalYearRequest): Observable<ApiResponse<FiscalYear>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/fiscal_years/irregular`, fiscalYear);
  }

  public createRegularFiscalYear(fiscalYear: FiscalYearCreateRegularRequest): Observable<ApiResponse<FiscalYear>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/fiscal_years/regular`, fiscalYear);
  }

  public openFiscalYear(
    fiscalYearId: string,
    fiscalYearNotesRequest: FiscalYearNotesRequest,
  ): Observable<ApiResponse<FiscalYear>> {
    this.cacheService.clearCategory('year');

    return this.apiService.post(
      `${this.servicePath}${this.resource}/fiscal_years/${fiscalYearId}/open`,
      fiscalYearNotesRequest,
    );
  }

  public closeFiscalYear(
    fiscalYearId: string,
    fiscalYearNotesRequest?: FiscalYearNotesRequest,
  ): Observable<ApiResponse<FiscalYear>> {
    this.cacheService.clearCategory('year');

    return this.apiService.post(
      `${this.servicePath}${this.resource}/fiscal_years/${fiscalYearId}/close`,
      fiscalYearNotesRequest,
    );
  }

  public validateFiscalYearClosing(fiscalYearId: string): Observable<ApiResponse<FiscalYearClosingValidation>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/fiscal_years/${fiscalYearId}/usage_count`);
  }

  public publishFiscalYearFrameworkTaxonomyStatus(fiscalYearId: string): Observable<ApiResponse<FiscalYear>> {
    this.cacheService.clearCategory('year');

    return this.apiService.post(`${this.servicePath}${this.resource}/fiscal_years/${fiscalYearId}/publish`);
  }
}
