<div mat-dialog-title class="mb-0 fx-col">
  <div class="fx-row justify-space-between">
    <h3 class="mat-h3 mat-primary mb-2">{{ "Add variables" | translate }}</h3>
    <a (click)="closeDialog()">
      <mat-icon svgIcon="close"></mat-icon>
    </a>
  </div>
  <div class="tabs mat-body-1" *ngIf="tabIndex">
    <a (click)="switchTab(1)" [ngClass]="{ active: tabIndex === 1 }">{{ "From this metric" | translate }}</a>
    <a (click)="switchTab(2)" [ngClass]="{ active: tabIndex === 2 }">{{ "From another metric" | translate }}</a>
  </div>
</div>

<div mat-dialog-content class="panel-dialog" [ngClass]="{ loaded: loaded }">
  <div class="fx-col" style="height: 65vh">
    <div class="form no-gap mt-3">
      <div class="formfield large required" *ngIf="tabIndex === 2">
        <lib-auto-complete-input
          [label]="'Select a metric' | translate"
          [hint]="'Start typing to see metrics' | translate"
          [control]="metricFormControl"
          [canAddNewOption]="false"
          [options]="(indicators$ | async) ?? []"
          [bindLabel]="bindMetricLabel"
          [minimalNumberOfCharToDisplayAutocomplete]="0"
          [comparisonMethod]="eAutoCompleteInputComparisonMethod.INCLUDES"
          [isLoadingOptions]="isLoadingInterMetrics"
          (selectValue)="selected($event)"
        ></lib-auto-complete-input>
      </div>

      <div class="tip large mv-4">
        <div class="fx-row align-center">
          <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
        </div>
        <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
          {{
            "Only number fields and table totals in this metric can be used as variables for a number calculation field."
              | translate
          }}
        </div>
      </div>
      <lib-divider class="mb-1 formfield large"></lib-divider>
    </div>
    <div class="tag color-grey-800 formfield large mb-4">{{ "Choose values" | translate }}</div>
    <div class="formfield large item-dummies-container fx-grow">
      <div class="h-100 item-dummies bkg-white">
        <div *ngFor="let i of [1, 2, 3, 4]" class="mb-2">
          <div class="mat-body-1 rounded-corners bkg-blink w-50">&nbsp;</div>
        </div>
      </div>
      <div
        *ngFor="let group of selectedMetric?.value_definition_groups | filterUncalculableGroups; let i = index"
        class="formfield large mb-2"
      >
        <div class="fx-row mb-2">
          <mat-icon svgIcon="group" class="color-grey-800 icon-md"></mat-icon>
          <div class="ml-2 tag color-grey-800">
            {{ group.label ?? "Group {iteration}" | translate: { iteration: i + 1 } }}
          </div>
        </div>
        <div class="fx-col">
          <ng-container *ngFor="let valueDefinition of group.value_definitions">
            <mat-checkbox
              *ngIf="
                (valueDefinition.type | isSupportedTypeAsVariable) && valueDefinition.id !== targetValueDefinitionId
              "
              [value]="valueDefinition.id"
              [checked]="isValueDefAdded(valueDefinition.id)"
              (change)="checkboxChanged($event, group.id, valueDefinition.id)"
              color="primary"
              class="ml-4 mat-body-2"
              [attr.data-testid]="'vd-option-' + valueDefinition.id"
            >
              <span>{{ valueDefinition.label }}</span>
              <span *ngIf="valueDefinition | isDeactivated">
                {{
                  "(Inactive from Fiscal Year {fiscalYear})"
                    | translate: { fiscalYear: valueDefinition.end_fiscal_year_frequency_code }
                }}
              </span>
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
      <lib-empty-results *ngIf="!selectedMetric" [displayData]="emptyResults" class="h-100"></lib-empty-results>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button type="submit" class="btn btn-success" (click)="addVariables()" [disabled]="!valueDefinitionsChecked.length">
    <div class="btn-text">
      {{
        "Add {count, plural, =0{variable} other{{count} variables} }"
          | translate: { count: valueDefinitionsChecked.length }
      }}
    </div>
  </button>
</div>
