export type TypeDetails =
  | BooleanTypeDetails
  | CalculatedTypeDetails
  | ChoiceTypeDetails
  | DateTypeDetails
  | NumberTypeDetails
  | DocumentTypeDetails
  | FileTypeDetails
  | FileTypeDetailsV2
  | LabelTypeDetails
  | SubtitleTypeDetails
  | TextTypeDetails
  | TipTypeDetails
  | MetricLinkTypeDetails;

export type QuantitativeTypeDetails = NumberTypeDetails | CalculatedTypeDetails;
export type TriggerTypeDetails = ChoiceTypeDetails | BooleanTypeDetails | NumberTypeDetails;

export interface TextTypeDetails {
  textarea: boolean;
  placeholder?: string;
  rich_text: boolean;
}

export interface LabelTypeDetails {
  value: string;
}

export interface SubtitleTypeDetails {
  value: string;
}

export enum TipTypeDetailsIcon {
  tip = 'tip',
  info = 'info',
  warning = 'warning',
  none = 'none',
}

export interface TipTypeDetails {
  value: string;
  icon: TipTypeDetailsIcon;
}

export interface NumberTypeDetails {
  units: string;
  type?: string;
  family: string;
  max_decimals: number;
}

export interface DateTypeDetails {
  format: string;
  format_python?: string;
}

export enum ChoiceFieldWidgetType {
  single_select = 'single_select',
  checkboxes = 'checkboxes',
  radio_buttons = 'radio_buttons',
  multi_chip = 'multi_chip',
}

export enum ChoiceFieldSource {
  core = 'core',
  platform = 'platform',
}

export interface ChoiceTypeDetails {
  choices?: string[] | null;
  display_explanation?: boolean;
  explanation_label?: string;
  explanation_required?: boolean;
  multi_choices?: boolean;
  allow_add_option?: boolean;
  widget_type?: ChoiceFieldWidgetType;
  selection_set_category_id?: string | null;
  selection_set_id?: string | null;
  selection_set_source?: ChoiceFieldSource | null;
}

export interface BooleanTypeDetails {
  prompt_on_false?: boolean;
  on_false_text_required?: boolean;
  prompt_on_true?: boolean;
  on_true_text_required?: boolean;
  label_true?: string;
  label_false?: string;
  label_prompt_true?: string;
  label_prompt_false?: string;
}

export enum TypeDetailsIntegerType {
  money = 'money',
}

export interface CalculatedTypeDetails {
  formula: string;
  value_refs: Record<string, CalculatedValueReferences>;
  units?: string;
  type?: TypeDetailsIntegerType;
  family?: string;
  max_decimals?: number;
}

export interface CalculatedValueReferences {
  value_definition_id: string;
  value_definition_group_id: string;
  metric_id: string;
  mandatory: boolean;
}

export interface FileTypeDetails {
  multiple: boolean;
  max_files: number;
}

export interface FileTypeDetailsV2 {
  multiple: boolean;
  max_files: number;
  display_url: boolean;
  url_required: boolean;
  display_page_number: boolean;
  page_number_required: boolean;
  display_explanation: boolean;
  explanation_required: boolean;
  explanation_label: string;
  attachment_optional: boolean;
  attachment_enabled: boolean;
}

export type DocumentHostEnv = 'platform' | 'core';

export interface DocumentTypeDetails {
  document_id: string;
  display_name?: string;
  document_host_env: DocumentHostEnv;
}

export interface MetricLinkTypeDetails {
  metric_id: string;
  indicator_id: string;
}
