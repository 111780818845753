import { Injectable } from '@angular/core';

export enum Platforms {
  CLIENT = 'client',
  ADMIN = 'admin',
  ADD_IN = 'add-in',
}

export enum LocalClientCodes {
  NOVISTO = 'platform',
  SLOWVISTO = 'slowvisto',
  MEGAVISTO = 'megavisto',
  SINGLESOURCE = 'singlesource',
  GROUPS = 'groups',
  SINGLEGROUP = 'singlegroup',
  HIERARCHY = 'hierarchy',
}

export interface APIConfig {
  minivisto: boolean;
  minivistoPort: string;
  minivistoSSL: boolean;
  localClientCode: LocalClientCodes;
  pageSize: number;
  apis: ApplicationApiDefinition;
  components: {
    editor: {
      toolbar: Record<string, unknown>;
      pasteCleanupSettings: Record<string, unknown>;
    };
    [component: string]: Record<string, unknown>;
  };
}

export interface ApplicationApiDefinition {
  auth: BaseAPI<AuthResource>;
  collect: BaseAPI<CollectResource>;
  platform: BaseAPI<PlatformResource>;
  documents: BaseAPI<DocumentsResource>;
  admin: BaseAPI<AdminResource>;
  search: BaseAPI<SearchResource>;
  public: BaseAPI<PublicResource>;
  messaging: BaseAPI<MessagingResource>;
}

interface BaseAPI<T> {
  api: string;
  localPort: string;
  resources: T;
}

interface AuthResource {
  auth_tokens: string;
  auth_basic: string;
  users: string;
  oauth: string;
  saml: string;
  config: string;
  licenseeSettings: string;
  loginEvents: string;
  data: string;
  featureFlags: string;
}

interface DocumentsResource {
  documents: string;
  resources: string;
  assets: string;
  dataRequestDocuments: string;
}

interface CollectResource {
  metrics: string;
  benchmarks: string;
  benchmarksV2: string;
  customIndicators: string;
  indicators: string;
  reports: string;
  dataRequests: string;
  dataRequestIndicators: string;
  collaborators: string;
  collaboratorsV2: string;
  optionLists: string;
  tags: string;
  targets: string;
  companies: string;
  businessUnits: string;
  businessUnitsV2: string;
  taskExecutions: string;
  userGroups: string;
  metricGroups: string;
  licenseeSettings: string;
  licenseeIntegrationSettings: string;
  userSettings: string;
  valueDefinitionGroups: string;
  fiscalYears: string;
  fiscalYearsAuditTrail: string;
  spIntegration: string;
  dashboards: string;
  dataTables: string;
  taxonomies: string;
  fieldRelations: string;
  consolidationV2: string;
}

export interface PlatformResource {
  global: string;
  core: string;
}

interface AdminResource {
  metrics: string;
  selection_sets: string;
  unit_groups: string;
  frameworks: string;
  topics: string;
  orgs: string;
  industries: string;
  standard_codes: string;
  option_lists: string;
  taxonomies: string;
  units: string;
  reports: string;
  clients: string;
  integration_partner_logins: string;
}

interface SearchResource {
  documents_search: string;
  resources: string;
}

interface PublicResource {
  core: string;
  dataRequestCollab: string;
  dataRequestCollabV2: string;
  platform: string;
  dataRequests: string;
  reports: string;
  dashboards: string;
  optionLists: string;
}

interface MessagingResource {
  websockets: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  production: boolean = false;
  version: string = '';
  date: string = '';
  pageSize: number = 0;
  platform?: Platforms;
  api = {
    fileFormats: {
      excel: '',
    },
  };
  apiConfig: APIConfig = {
    apis: {
      auth: {
        api: '',
        localPort: '',
        resources: {
          auth_tokens: '',
          oauth: '',
          saml: '/saml/v1',
          auth_basic: '/basic/v1',
          users: '/users/v1',
          config: 'client_configs/v1',
          licenseeSettings: '/client_settings/v1',
          loginEvents: '/login_events/v1',
          data: '/data/v1',
          featureFlags: '/feature_flags/v1',
        },
      },
      collect: {
        api: '/collect',
        localPort: '8084',
        resources: {
          metrics: '/metrics/v1',
          benchmarks: '/benchmarks/v1',
          benchmarksV2: '/benchmarks/v2',
          indicators: '/indicators/v1',
          reports: '/reports/v2',
          tags: '/tags/v1',
          targets: '/targets/v1',
          dataRequests: '/data_requests/v1',
          dataRequestIndicators: '/data_request_indicators/v1',
          customIndicators: '/custom',
          companies: '/companies/v1',
          businessUnits: '/business_units/v1',
          businessUnitsV2: '/business_units/v2',
          collaborators: '/data_request_collabs/v1',
          collaboratorsV2: '/data_request_collabs/v2',
          taskExecutions: '/task_executions/v1',
          userGroups: '/user_groups/v1',
          metricGroups: '/metric_groups/v1',
          licenseeSettings: '/client_settings/v1',
          licenseeIntegrationSettings: '/integration_settings/v1',
          userSettings: '/user_settings/v1',
          valueDefinitionGroups: '/value_definition_groups/v1',
          fiscalYears: '/fiscal_years/v1',
          fiscalYearsAuditTrail: '/fiscal_years_audit_trails/v1',
          spIntegration: '/sp/v1',
          dashboards: '/dashboards/v1',
          dataTables: '/data_tables/v1',
          taxonomies: '/taxonomies/v1',
          fieldRelations: '/field_relations/v1',
          consolidationV2: '/consolidation/v2',
          optionLists: '/option_lists/v1',
        },
      },
      documents: {
        api: '/documents',
        localPort: '8083',
        resources: {
          documents: '/documents/v1',
          resources: '/resources/v1',
          assets: '',
          dataRequestDocuments: '/data_request_documents/v1',
        },
      },
      public: {
        api: '/public',
        localPort: '8089',
        resources: {
          core: '/core/v1',
          platform: '/platform/v1',
          dataRequestCollab: '/data_request_collabs/v1',
          dataRequestCollabV2: '/data_request_collabs/v2',
          dataRequests: '/data_requests/v1',
          reports: '/reports/v2',
          dashboards: '/dashboards/v1',
          optionLists: '/option_lists/v1',
        },
      },
      admin: {
        api: '/admin',
        localPort: '8088',
        resources: {
          metrics: '/metrics/v1',
          standard_codes: '/standard_codes/v1',
          option_lists: '/option_lists/v1',
          frameworks: '/frameworks/v1',
          orgs: '/orgs/v1',
          industries: '/industries/v1',
          taxonomies: '/taxonomies/v1',
          topics: '/topics/v1',
          selection_sets: '/selection_sets/v1',
          unit_groups: '/unit_groups/v1',
          units: '/units/v1',
          reports: '/reports/v2',
          clients: '/data/v1',
          integration_partner_logins: '/integration_partner_logins/v1',
        },
      },
      messaging: {
        api: '/broadcast',
        localPort: '8086',
        resources: {
          websockets: '/websockets/v1',
        },
      },
      platform: {
        api: '/platform',
        localPort: '8085',
        resources: {
          global: '/platform/v1',
          core: 'core/v1',
        },
      },
      search: {
        api: '/search',
        localPort: '8081',
        resources: {
          resources: '/search/v1',
          documents_search: '/documents/v1',
        },
      },
    },
    components: {
      editor: {
        toolbar: {},
        pasteCleanupSettings: {},
      },
    },
    pageSize: 0,
    minivisto: false,
    minivistoPort: '9080',
    minivistoSSL: false,
    localClientCode: LocalClientCodes.NOVISTO,
  };
}

export const DialogConfig = {
  small: {
    width: '42rem',
    maxHeight: '42rem',
    position: { top: '8rem' },
  },
  medium: {
    width: '68rem',
    position: { top: '8rem' },
  },
  large: {
    maxWidth: '140rem',
  },
};

export enum AuthMethod {
  undefined = 'undefined',
  basic = 'basic',
  ldap = 'ldap',
}

export enum UserRoles {
  admin = 'admin',
  manager = 'manager',
  editor = 'editor',
  viewer = 'viewer',
  collaborator = 'collaborator',
}

export enum AdminUserRoles {
  admin = 'admin',
  editor = 'editor',
  analyst = 'analyst',
  dataManager = 'data_manager',
}
