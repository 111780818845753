import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BUTTON_COMPONENTS } from './buttons';
import { CARD_COMPONENTS } from './cards';
import { INDICATOR_COMPONENTS } from './indicators';
import { TAGS_COMPONENTS } from './tags';
import { TYPOGRAPHY_COMPONENTS } from './typography';
import { BADGE_COMPONENTS } from './badges';
import { FocusStateDirective, FORM_COMPONENTS } from './form';
import { FILES_COMPONENTS } from './files';
import { CHIPS_COMPONENTS } from './chips';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../directives';
import { CdkModule } from '../material/cdk.module';
import { TEXTS_COMPONENTS } from './texts';
import { TranslateModule } from '@ngx-translate/core';
import { NumberMaskModule } from '../number-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';
import { MENU_COMPONENTS } from './menus';
import { MatRippleModule } from '@angular/material/core';
import { LOADINGS_COMPONENTS } from './loadings';
import { STEPPER_COMPONENTS } from './steppers';
import { WIDGETS_COMPONENTS } from './widgets';
import { LISTS_COMPONENTS } from './lists';
import { NumericInputTypePipe } from './form/numeric-input/numeric-type-pipe/numeric-input-type.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TOOLTIP } from './tooltip';
import { AccordionComponent } from './accordion/accordion.component';
import { LibInfoBannerComponent } from './widgets/banners/lib-info-banner/lib-info-banner.component';
import { EditorModule } from 'primeng/editor';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ContentNotAvailablePageComponent } from './pages/content-not-available-page/content-not-available-page.component';
import { PasskeyButtonComponent } from './buttons/passkey-button/passkey-button.component';
import { MatSliderModule } from '@angular/material/slider';
import { SidebarModule } from 'primeng/sidebar';
import { OVERLAY_COMPONENTS } from './overlay';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { MessageService } from 'primeng/api';
import { TreeSelectModule } from 'primeng/treeselect';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [
    ...CARD_COMPONENTS,
    ...BUTTON_COMPONENTS,
    ...INDICATOR_COMPONENTS,
    ...TAGS_COMPONENTS,
    ...TYPOGRAPHY_COMPONENTS,
    ...BADGE_COMPONENTS,
    ...FORM_COMPONENTS,
    ...TEXTS_COMPONENTS,
    ...FILES_COMPONENTS,
    ...MENU_COMPONENTS,
    ...CHIPS_COMPONENTS,
    ...LOADINGS_COMPONENTS,
    ...STEPPER_COMPONENTS,
    ...WIDGETS_COMPONENTS,
    ...LISTS_COMPONENTS,
    ...TOOLTIP,
    ...OVERLAY_COMPONENTS,
    FocusStateDirective,
    NumericInputTypePipe,
    TooltipComponent,
    AccordionComponent,
    LibInfoBannerComponent,
    ContentNotAvailablePageComponent,
    PasskeyButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CdkModule,
    MaterialModule,
    MatSliderModule,
    // PrimeNG
    EditorModule,
    MultiSelectModule,
    SidebarModule,
    PanelMenuModule,
    TagModule,
    MessagesModule,
    MessageModule,
    // SyncFusion
    RichTextEditorModule,
    GridModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    NumberMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DocumentEditorContainerModule,
    MatRippleModule,
    DropdownModule,
    TreeSelectModule,
    MenuModule,
    AvatarModule,
    BadgeModule,
  ],
  providers: [provideNgxMask(), MessageService],
  exports: [
    ...CARD_COMPONENTS,
    ...BUTTON_COMPONENTS,
    ...INDICATOR_COMPONENTS,
    ...TAGS_COMPONENTS,
    ...TYPOGRAPHY_COMPONENTS,
    ...BADGE_COMPONENTS,
    ...FORM_COMPONENTS,
    ...TEXTS_COMPONENTS,
    ...FILES_COMPONENTS,
    ...MENU_COMPONENTS,
    ...CHIPS_COMPONENTS,
    ...STEPPER_COMPONENTS,
    ...WIDGETS_COMPONENTS,
    ...LOADINGS_COMPONENTS,
    ...LISTS_COMPONENTS,
    ...TOOLTIP,
    ...OVERLAY_COMPONENTS,
    FocusStateDirective,
    NumericInputTypePipe,
    AccordionComponent,
    ContentNotAvailablePageComponent,
    PasskeyButtonComponent,
  ],
})
export class ComponentsModule {}
