<p class="ytd-label">{{ indicatorName }}</p>
<div class="ytd-field">
  <lib-numeric-input
    [readonly]="true"
    [labelPosition]="'left'"
    [label]="'YTD ' + yearToDateCalculation"
    [control]="control"
    [sameSizeLabels]="true"
  ></lib-numeric-input>
</div>
<div
  class="periods-fields"
  [ngClass]="{
    'periods-fields-quarterly': frequency === 'quarterly',
    'periods-fields-monthly': frequency === 'monthly',
  }"
>
  <lib-numeric-input
    *ngFor="let freqControl of frequenciesControl"
    [labelPosition]="'left'"
    [label]="freqControl.valueRef.fiscal_year_period! | frequencyFieldLabel: frequency"
    [matTooltip]="freqControl.valueRef.fiscal_year_period! | frequencyFieldPeriod"
    [control]="freqControl"
    [sameSizeLabels]="true"
  ></lib-numeric-input>
</div>
