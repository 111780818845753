import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { map } from 'rxjs';

import { OptionListsApiService } from '../../services/api-services';
import { OptionListItem, UpsertOptionListItemRequest } from '../../models';
import { UniquenessValidator, WhitespaceValidators } from '../../validators';
import { ValidatorsUtils } from '../../classes';

type OptionListItemFormModel = {
  active: FormControl<boolean>;
  cdpOptionId: FormControl<string | null>;
  cdpOptionGroupId: FormControl<string | null>;
  displayExplanation: FormControl<boolean>;
  explanationRequired: FormControl<boolean>;
  name: FormControl<string>;
  optionListItemId: FormControl<string | null>;
  spDataDpdValue: FormControl<string | null>;
};

export class OptionListItemForm extends FormGroup<OptionListItemFormModel> {
  constructor(
    readonly optionListsApiService: OptionListsApiService,
    readonly optionListId: string,
    optionListItem?: OptionListItem,
    fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      active: fb.control(optionListItem?.active ?? true, { nonNullable: true }),
      cdpOptionId: fb.control(
        optionListItem?.cdp_details?.cdp_option_id ? String(optionListItem.cdp_details.cdp_option_id) : null,
        {
          validators: [ValidatorsUtils.stringToIntegerValidator()],
        },
      ),
      cdpOptionGroupId: fb.control(
        optionListItem?.cdp_details?.cdp_option_group_id
          ? String(optionListItem.cdp_details.cdp_option_group_id)
          : null,
        {
          validators: [ValidatorsUtils.stringToIntegerValidator()],
        },
      ),
      displayExplanation: fb.control(optionListItem?.display_explanation ?? false, { nonNullable: true }),
      explanationRequired: fb.control(optionListItem?.explanation_required ?? false, { nonNullable: true }),
      name: fb.control(optionListItem?.name || '', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(200), WhitespaceValidators.hasOnlyWhitespace()],
        asyncValidators: UniquenessValidator.validate(
          (value: string) =>
            optionListsApiService.checkOptionListItemName(optionListId, value).pipe(map((res) => res.data.available)),
          optionListItem ? [optionListItem.name] : [],
        ),
      }),
      optionListItemId: fb.control(optionListItem?.id || null),
      spDataDpdValue: fb.control(optionListItem?.sp_data_dpd_value ? String(optionListItem.sp_data_dpd_value) : null),
    });
  }

  public toModel(): UpsertOptionListItemRequest {
    const cdp_option_id =
      typeof this.controls.cdpOptionId.value === 'string' ? Number(this.controls.cdpOptionId.value) : undefined;
    const cdp_option_group_id =
      typeof this.controls.cdpOptionGroupId.value === 'string' ? Number(this.controls.cdpOptionGroupId.value) : null;

    return {
      active: this.controls.active.value,
      cdp_details: cdp_option_id ? { cdp_option_id, cdp_option_group_id } : undefined,
      display_explanation: this.controls.displayExplanation.value,
      explanation_required: this.controls.displayExplanation.value && this.controls.explanationRequired.value,
      name: this.controls.name.value.trim(),
      sp_data_dpd_value: this.controls.spDataDpdValue.value || undefined,
    };
  }
}
