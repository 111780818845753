import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { TranslateService } from '../../common/translate/translate.service';
import { ClientBenchmarksService } from '../client-benchmarks/client-benchmarks.service';
import { ClientCompaniesService } from '../client-companies/client-companies.service';
import { ClientCoreService } from '../client-core/client-core.service';
import { ClientDataRequestSourcesService } from '../client-data-request-sources/client-data-request-sources.service';
import { ClientDataRequestsService } from '../client-data-requests/client-data-requests.service';
import { ClientDatasetsService } from '../client-datasets/client-datasets.service';
import { ClientDocumentsService } from '../../client/client-documents/client-documents.service';
import { ClientIndicatorsService } from '../../client/client-indicators/client-indicators.service';
import { ClientResourcesService } from '../../client/client-resources/client-resources.service';
import { ClientReportsService } from '../client-reports/client-reports.service';
import { ClientGlobalService } from '../client-global/client-global.service';
import { ClientPeersService } from '../client-peers/client-peers.service';
import { ClientTagsService } from '../client-tags/client-tags.service';
import { ClientUsersService } from '../client-users/client-users.service';
import {
  ApiResponse,
  SearchOptions,
  ResourceType,
  ItemType,
  SearchBarFilterResourceType,
  SearchBarFilterResourceArgs,
  ListUsersSearchOptions,
} from '../../../models';
import { AuthService } from '../../common/auth/auth.service';
import { ClientSourcesV2Service } from '../client-sources/client-sources-v2.service';
import { ClientStandardCodeService } from '../client-standard-codes/client-standard-codes.service';
import {
  CommonCategories,
  CommonTypes,
  DataRequestsRequestStatus,
  DataRequestsSourceStatus,
  DocsDocStatusFilter,
  MetricDataExportTypes,
  MetricsIndicatorCategories,
  MetricsIndicatorSort,
  MetricsIndicatorStatus,
  MetricsMetricCategories,
  MetricsMetricSort,
  PeersPeerSort,
  ReportsReportCategories,
  ReportsReportStatus,
  SearchSort,
  SourcesSourceSort,
  SourcesSourceTypes,
  UsersUserStatus,
} from '../../../translations';
import { ClientPublicPlatformService } from '../client-public-platform/client-public-platform.service';
import { ClientPublicCoreService } from '../client-public-core/client-public-core.service';
import { ClientMetricGroupsService } from '../client-metric-groups/client-metric-groups.service';
import { SourcesService } from '../../common/sources/sources.service';
import { ClientFiscalYearsService } from '../client-fiscal-years/client-fiscal-years.service';
import { ClientLicenseeIntegrationSettingsService } from '../client-licensee-integration-settings/client-licensee-integration-settings.services';
import { ClientTaxonomiesService } from '../client-taxonomies/client-taxonomies.service';

@Injectable({
  providedIn: 'root',
})
export class ClientSearchService {
  pageSize: number = this.baseService.defaultSize;

  constructor(
    private authService: AuthService,
    private baseService: BaseService,
    private translateService: TranslateService,
    private documentsService: ClientDocumentsService,
    private resourcesService: ClientResourcesService,
    private indicatorsService: ClientIndicatorsService,
    private benchmarksService: ClientBenchmarksService,
    private datasetsService: ClientDatasetsService,
    private reportsService: ClientReportsService,
    private globalService: ClientGlobalService,
    private coreService: ClientCoreService,
    private publicPlatformService: ClientPublicPlatformService,
    private publicCoreService: ClientPublicCoreService,
    private clientPeersService: ClientPeersService,
    private tagsService: ClientTagsService,
    private usersService: ClientUsersService,
    private companiesService: ClientCompaniesService,
    private dataRequestsService: ClientDataRequestsService,
    private dataRequestSourcesService: ClientDataRequestSourcesService,
    private clientSourcesV2Service: ClientSourcesV2Service,
    private sourcesService: SourcesService,
    private clientStandardCodeService: ClientStandardCodeService,
    private clientMetricGroupsService: ClientMetricGroupsService,
    private clientFiscalYearsService: ClientFiscalYearsService,
    private clientLicenseeIntegrationSettingsService: ClientLicenseeIntegrationSettingsService,
    private clientTaxonomiesService: ClientTaxonomiesService,
  ) {}

  search(searchOptions: SearchOptions): Observable<ApiResponse<unknown[]>> {
    switch (searchOptions.item_type) {
      case ItemType.docs_doc:
      case ItemType.metric_data_exports:
        return this.documentsService.search(searchOptions);
      case ItemType.docs_report:
      case ItemType.docs_resource:
        return this.resourcesService.search(searchOptions);
      case ItemType.metrics_metric:
      case ItemType.related_metrics:
        return this.indicatorsService.searchMetrics(searchOptions);
      case ItemType.metrics_indicator:
        return this.indicatorsService.search(searchOptions);
      case ItemType.metrics_template:
        return this.coreService.searchMetrics(searchOptions);
      case ItemType.reports_report:
        return this.reportsService.search(searchOptions);
      case ItemType.reports_template:
        return this.coreService.searchTemplates(searchOptions);
      case ItemType.reports_indicators:
        return this.reportsService.searchReportIndicators(searchOptions);
      case ItemType.analyze_dataset:
        return this.datasetsService.searchDatasets(searchOptions);
      case ItemType.benchmarks_benchmark:
        return this.benchmarksService.search(searchOptions);
      case ItemType.peers_peer:
        return this.clientPeersService.search(searchOptions);
      case ItemType.tags_tag:
        return this.tagsService.search(searchOptions);
      case ItemType.core_orgs:
        return this.coreService.searchOrgs(searchOptions);
      case ItemType.users_user:
        return this.usersService.search(searchOptions);
      case ItemType.data_requests_request:
        return this.dataRequestsService.listDataRequests(searchOptions);
      case ItemType.sources_source:
        return this.clientSourcesV2Service.search(searchOptions);
      case ItemType.data_requests_source:
        return this.dataRequestSourcesService.listDataRequestSources(searchOptions.custom_filters?.id as string);
      case ItemType.standard_codes:
        return this.clientStandardCodeService.listStandardCodes(searchOptions);
      case ItemType.integration_settings:
        return this.clientLicenseeIntegrationSettingsService.getAllIntegrations();
      default:
        throw new Error(`Search item type not supported: ${searchOptions.item_type ?? ''}`);
    }
  }

  searchResources(
    resourceType: string,
    item?: string,
    searchArguments: Record<string, string | string[] | boolean> | SearchBarFilterResourceArgs = {},
  ): Observable<ApiResponse<unknown[]>> {
    switch (resourceType) {
      case ResourceType.year:
        return this.clientFiscalYearsService.listFiscalYears();
      case ResourceType.year_public:
        return this.publicPlatformService.listFiscalYears(searchArguments);
      case ResourceType.source:
        switch (item) {
          case 'unit':
            return this.sourcesService.listSources();
          case 'org':
            return this.globalService.listOrgs();
          default:
            throw new Error(`Source is not supported for : ${item ?? ''}`);
        }
      case ResourceType.topic:
        return this.coreService.listTopicCategories();
      case ResourceType.category:
        const omitCategories: string[] | undefined = (searchArguments as SearchBarFilterResourceArgs)[
          SearchBarFilterResourceType.category
        ]?.excluded;
        switch (item) {
          case 'metrics_indicator':
            return of({
              data: this.translateService.listResources(MetricsIndicatorCategories, omitCategories),
              errors: [],
              meta: {},
            });
          case 'metrics_metric':
          case 'metric':
            return of({
              data: this.translateService.listResources(MetricsMetricCategories, omitCategories),
              errors: [],
              meta: {},
            });
          case 'doc':
            return of({
              data: this.translateService.listResources(CommonCategories),
              errors: [],
              meta: {},
            });
          case 'reports_report':
          case 'reports_template':
          case 'report':
            return of({
              data: this.translateService.listResources(ReportsReportCategories),
              errors: [],
              meta: {},
            });
          default:
            throw new Error(`Category is not supported for : ${item ?? ''}`);
        }
      case ResourceType.type:
        switch (item) {
          case ItemType.sources_source:
            return of({
              data: this.translateService.listResources(SourcesSourceTypes),
              errors: [],
              meta: {},
            });
          case ItemType.metric_data_exports:
            return of({
              data: this.translateService.listResources(MetricDataExportTypes),
              errors: [],
              meta: {},
            });
          default:
            return of({
              data: this.translateService.listResources(CommonTypes),
              errors: [],
              meta: {},
            });
        }
      case ResourceType.standard_codes:
        return this.coreService.listFrameworks('standard_code');
      case ResourceType.framework:
        return this.coreService.listFrameworks();
      case ResourceType.industry:
        return this.coreService.listAllIndustries();
      case ResourceType.tag:
        return this.tagsService.list();
      case ResourceType.status:
        switch (item) {
          case 'docs_doc':
          case 'metric_data_exports':
            return of({ data: this.translateService.listResources(DocsDocStatusFilter), errors: [], meta: {} });
          case 'metrics_indicator': {
            return of({
              data: this.translateService.listResources(MetricsIndicatorStatus),
              errors: [],
              meta: {},
            });
          }
          case 'reports_report': {
            return of({
              data: this.translateService.listResources(ReportsReportStatus),
              errors: [],
              meta: {},
            });
          }
          case 'users_user': {
            return of({
              data: this.translateService.listResources(UsersUserStatus),
              errors: [],
              meta: {},
            });
          }
          case 'data_requests_request': {
            return of({
              data: this.translateService.listResources(DataRequestsRequestStatus),
              errors: [],
              meta: {},
            });
          }
          case 'data_requests_source': {
            return of({
              data: this.translateService.listResources(DataRequestsSourceStatus),
              errors: [],
              meta: {},
            });
          }
          default:
            throw new Error(`Status is not supported for : ${item ?? ''}`);
        }
      case ResourceType.author:
        return of();
      case ResourceType.sort:
        switch (item) {
          case 'metrics_indicator':
            return of({
              data: this.translateService.listResources(MetricsIndicatorSort),
              errors: [],
              meta: {},
            });
          case 'metrics_metric':
            return of({
              data: this.translateService.listResources(MetricsMetricSort),
              errors: [],
              meta: {},
            });
          case 'peers_peer':
            return of({
              data: this.translateService.listResources(PeersPeerSort),
              errors: [],
              meta: {},
            });
          case ItemType.sources_source:
            return of({
              data: this.translateService.listResources(SourcesSourceSort),
              errors: [],
              meta: {},
            });
          default:
            return of({ data: this.translateService.listResources(SearchSort), errors: [], meta: {} });
        }
      case ResourceType.unit:
        switch (item) {
          case 'public_data_request':
            return this.publicCoreService.listUnits();
          case 'families':
            return this.coreService.listUnitFamilies();
          default:
            return this.coreService.listUnits(item);
        }
      case ResourceType.org:
        return this.globalService.listOrgs();
      case ResourceType.template:
        switch (item) {
          case ItemType.reports_report:
            return this.coreService.listTemplates();
          default:
            throw new Error(`Template is not supported for : ${item ?? ''}`);
        }
      case ResourceType.user:
        return this.usersService.listUsers(searchArguments as ListUsersSearchOptions);
      case ResourceType.min_metrics:
        return this.globalService.listMinMetrics();
      case ResourceType.role:
        return of({ data: this.authService.getUserRoles(), errors: [], meta: {} });
      case ResourceType.metric_group:
        return this.clientMetricGroupsService.listMetricGroups();
      case ResourceType.questionnaire_framework:
        return this.coreService.listThirdPartyFrameworks();
      case ResourceType.taxonomy:
        return this.clientTaxonomiesService.getFavoriteTaxonomies(true).pipe(
          map((res) => {
            res.data.sort((a, b) => a.label.localeCompare(b.label));
            return res;
          }),
        );
      default:
        throw new Error(`Resource type not supported: ${resourceType}`);
    }
  }
}
