<p-treeTable
  [value]="dataSource"
  [columns]="columns"
  [scrollable]="true"
  [(selection)]="selectedNodes"
  (onNodeSelect)="nodeSelect($event)"
  (onNodeUnselect)="nodeUnselect($event)"
  (onNodeExpand)="nodeExpand($event)"
  (onNodeCollapse)="nodeCollapse($event)"
  styleClass="p-treetable-gridlines"
>
  <ng-template pTemplate="header" *ngIf="showHeader" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.name }}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr [ttRow]="rowNode" [ttSelectableRow]="selectable" (click)="rowClick(rowData)">
      <td *ngFor="let col of columns; let i = index">
        <p-treeTableToggler
          [attr.data-testid]="'expand-toggler-' + rowData[col.dataKey]"
          [rowNode]="rowNode"
          *ngIf="i === 0"
        ></p-treeTableToggler>
        <ng-container *ngIf="selectable && (allNodesSelectable || rowData?.is_selectable) && i === 0">
          <p-treeTableCheckbox
            [attr.data-testid]="'selection-checkbox-' + rowData[col.dataKey]"
            [value]="rowNode"
            [disabled]="rowData.disabled"
          ></p-treeTableCheckbox>
        </ng-container>

        <ng-template #defaultCell>
          {{ rowData | get: col.dataKey }}
        </ng-template>

        <ng-container
          *ngTemplateOutlet="
            col?.cellTemplate || defaultCell;
            context: { $implicit: rowData | get: col.dataKey, row: rowData }
          "
        ></ng-container>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
