import {
  CDPInvalidConfigurationException,
  CDPInvalidTokenException,
  CDPJobInProgressException,
  SPJobInProgressException,
  PasswordReuseException,
  CDPResourceNotFoundException,
  CDPUnauthorizedException,
  CDPGeneralException,
  RequestAlreadyImportedException,
  RequestArchivedException,
  OAUTHStateExpiredOrInvalidException,
  OAUTHMissingEmailException,
  OAUTHUserNotFoundException,
  OAUTHInvalidGrantException,
  OAUTHInvalidClientException,
  OAUTHFetchUserInfoException,
  OAUTHUserInfoFormatException,
  SAMLProviderNotFoundException,
  SAMLProviderDisabledException,
  SAMLUserNotFoundException,
  MinimumValueDefinitionValidationException,
  ConvertBusinessUnitWithDataException,
  ConvertLastConsolidatedBusinessUnitException,
  ConvertConsolidatedBusinessUnitWithChildrenException,
  SPInvalidCredentialsException,
  ConvertBusinessUnitOnLevelFourException,
} from '../exceptions';
import {
  CDP_GENERAL_ERROR,
  CDP_INVALID_CONFIGURATION_ERROR,
  CDP_INVALID_TOKEN_ERROR,
  CDP_JOB_IN_PROGRESS_ERROR,
  CDP_RESOURCE_NOT_FOUND_ERROR,
  CDP_UNAUTHORIZED_ERROR,
  DATA_REQUEST_ALREADY_IMPORTED_ERROR,
  DATA_REQUEST_ARCHIVED_ERROR,
  OAUTH_FETCH_USER_INFO_ERROR,
  OAUTH_INVALID_CLIENT_ERROR,
  OAUTH_INVALID_GRANT_ERROR,
  OAUTH_MISSING_EMAIL_ERROR,
  OAUTH_STATE_EXPIRED_OR_INVALID_ERROR,
  OAUTH_USER_INFO_FORMAT_ERROR,
  OAUTH_USER_NOT_FOUND_ERROR,
  PASSWORD_REUSE,
  SAML_PROVIDER_DISABLED_ERROR,
  SAML_PROVIDER_NOT_FOUND_ERROR,
  SAML_USER_NOT_FOUND_ERROR,
  SP_JOB_IN_PROGRESS_ERROR,
  MINIMUM_VALUE_DEFINITION_VALIDATION_ERROR,
  CONVERT_BUSINESS_UNIT_WITH_DATA_ERROR,
  CONVERT_LAST_CONSOLIDATED_BUSINESS_UNIT_ERROR,
  CONVERT_CONSOLIDATED_BUSINESS_UNIT_WITH_CHILDREN_ERROR,
  REPORT_VALIDATION_ERROR,
  SP_INVALID_CREDENTIALS_ERROR,
  SP_UNAUTHORIZED_ERROR,
  CONVERT_BUSINESS_UNIT_ON_LEVEL_FOUR_ERROR,
} from '../constants';

export const ERRORS_EXCEPTION_MAP = new Map<string, Error>([
  [SP_JOB_IN_PROGRESS_ERROR, new SPJobInProgressException()],
  [CDP_JOB_IN_PROGRESS_ERROR, new CDPJobInProgressException()],
  [CDP_INVALID_CONFIGURATION_ERROR, new CDPInvalidConfigurationException()],
  [CDP_INVALID_TOKEN_ERROR, new CDPInvalidTokenException()],
  [CDP_RESOURCE_NOT_FOUND_ERROR, new CDPResourceNotFoundException()],
  [CDP_UNAUTHORIZED_ERROR, new CDPUnauthorizedException()],
  [CDP_GENERAL_ERROR, new CDPGeneralException()],
  [PASSWORD_REUSE, new PasswordReuseException()],
  [DATA_REQUEST_ALREADY_IMPORTED_ERROR, new RequestAlreadyImportedException()],
  [DATA_REQUEST_ARCHIVED_ERROR, new RequestArchivedException()],
  [MINIMUM_VALUE_DEFINITION_VALIDATION_ERROR, new MinimumValueDefinitionValidationException()],
  [CONVERT_BUSINESS_UNIT_WITH_DATA_ERROR, new ConvertBusinessUnitWithDataException()],
  [CONVERT_BUSINESS_UNIT_ON_LEVEL_FOUR_ERROR, new ConvertBusinessUnitOnLevelFourException()],
  [CONVERT_LAST_CONSOLIDATED_BUSINESS_UNIT_ERROR, new ConvertLastConsolidatedBusinessUnitException()],
  [CONVERT_CONSOLIDATED_BUSINESS_UNIT_WITH_CHILDREN_ERROR, new ConvertConsolidatedBusinessUnitWithChildrenException()],
]);

export const ERRORS_EXCEPTION_TYPE_TO_IGNORE: string[] = [REPORT_VALIDATION_ERROR];

export const AUTH_ERRORS_EXCEPTION_MAP = new Map<string, Error>([
  [OAUTH_STATE_EXPIRED_OR_INVALID_ERROR, new OAUTHStateExpiredOrInvalidException()],
  [OAUTH_MISSING_EMAIL_ERROR, new OAUTHMissingEmailException()],
  [OAUTH_USER_NOT_FOUND_ERROR, new OAUTHUserNotFoundException()],
  [OAUTH_INVALID_GRANT_ERROR, new OAUTHInvalidGrantException()],
  [OAUTH_INVALID_CLIENT_ERROR, new OAUTHInvalidClientException()],
  [OAUTH_FETCH_USER_INFO_ERROR, new OAUTHFetchUserInfoException()],
  [OAUTH_USER_INFO_FORMAT_ERROR, new OAUTHUserInfoFormatException()],
  [SAML_PROVIDER_NOT_FOUND_ERROR, new SAMLProviderNotFoundException()],
  [SAML_PROVIDER_DISABLED_ERROR, new SAMLProviderDisabledException()],
  [SAML_USER_NOT_FOUND_ERROR, new SAMLUserNotFoundException()],
  [SP_INVALID_CREDENTIALS_ERROR, new SPInvalidCredentialsException()],
  [SP_UNAUTHORIZED_ERROR, new SPInvalidCredentialsException()],
]);
