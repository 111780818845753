<label
  *ngIf="label !== ''"
  class="form-field-label label"
  [id]="id"
  [for]="for"
  [ngClass]="combinedClasses"
  data-testid="formFieldLabel"
>
  <span>{{ label }}</span>
  <div class="icon-container" *ngIf="isConsolidationEnabled(sourceConfiguration) && displayConsolidatedInfo">
    <mat-icon
      data-testid="labelInfoIcon"
      svgIcon="info"
      class="icon-md color-primary-900 ml-1"
      libTooltip
      [tooltip]="tooltip"
    ></mat-icon>
  </div>
  <div class="icon-container" *ngIf="displayDeleteIcon() || displayTableTotalInfo">
    <mat-icon
      *ngIf="displayTableTotalInfo"
      data-testid="labelInfoIcon"
      svgIcon="info"
      class="icon-md color-primary-900 ml-1"
      libTooltip
      [tooltip]="tooltip"
    ></mat-icon>

    <a (click)="deleteValue()" *ngIf="displayDeleteIcon()" data-testid="deleteIcon" class="pointer-event">
      <mat-icon class="field-clear icon-md ml-1" svgIcon="frequency"></mat-icon>
    </a>
  </div>
</label>

<ng-template #tooltip>
  <div class="tooltip">
    <div class="h-13 w-13">
      <mat-icon svgIcon="info" class="tip-icon color-primary-900 ml-2"></mat-icon>
    </div>
    <div class="tooltip-text">{{ fieldInfo }}</div>
  </div>
</ng-template>
