<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="actionsMenu"
  data-testid="actions-menu"
></div>
<mat-menu #actionsMenu="matMenu" (closed)="clearSelectedItems()">
  <ng-template matMenuContent>
    <a *ngFor="let item of actions" mat-menu-item (click)="onActionMenuSelect(item)">
      <mat-icon class="icon-sm" svgIcon="{{ item.icon }}"></mat-icon>
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<div class="h-100 item-dummies" *ngIf="!noLoaders">
  <div class="repeat bkg-white">
    <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]" class="mb-2">
      <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
      <div class="mat-body-1 rounded-corners bkg-blink w-50">&nbsp;</div>
    </div>
  </div>
</div>

<div class="text" [ngClass]="{ 'text-clamp text-clamp-4': clamp }" style="position: relative">
  <ng-container *ngIf="documents$ | async as documents">
    <ng-container *ngFor="let group of sanitizedOrderedValueGroups; last as isLastGroup">
      <ng-container *ngIf="isElementDisplayed(group)">
        <ng-container *ngIf="!group.table_id; else metricTableGroup">
          <ng-container *ngFor="let valueSet of group.values">
            <div
              *ngIf="isElementDisplayed(valueSet)"
              class="m-0 value-container"
              [ngClass]="{
                'mr-1 fx-row': valueSet.type !== 'tip',
                'no-action': disableEdit,
                active: isValueDefHighlighted(valueSet, group),
              }"
              (contextmenu)="onContextMenu($event, valueSet, group)"
              (mouseover)="setHoverIndex(valueSet, group)"
              (mouseout)="setHoverIndex()"
            >
              <div class="fx-col" *ngIf="!(group.repeatable && valueSet.type === 'calculated')">
                <ng-container
                  *ngIf="
                    !nullCategoryIncompatibleTypes.includes(valueSet.type) && showNullCategory && !valueSet.value;
                    else showValue
                  "
                >
                  <div *ngIf="valueSet.null_category; else defaultNullCategory" class="value">
                    {{ ePeerDataValueNullCategoriesLabels[valueSet.null_category] }}
                  </div>
                  <ng-template #defaultNullCategory>
                    <div class="value">
                      {{ ePeerDataValueNullCategoriesLabels[ePeerDataValueNullCategories.not_available] }}
                    </div>
                  </ng-template>
                </ng-container>
                <ng-template #showValue>
                  <ng-container [ngSwitch]="valueSet.type">
                    <!--subtitle-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.subtitle">
                      <h4 class="mat-subtitle-2">{{ valueSet.type_details?.value }}</h4>
                    </ng-container>

                    <!--text-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.text">
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet.label }}</div>
                      <div
                        *ngIf="valueSet.type_details.textarea"
                        class="value"
                        [ngClass]="{ active: selectedValue === valueSet }"
                        (click)="selectDefaultAction()"
                      >
                        <span [innerHTML]="valueSet.value | sanitizeHtml"></span>
                        <ng-container
                          *ngTemplateOutlet="hoverOptions; context: { value: valueSet, singleLine: true, group: group }"
                        >
                        </ng-container>
                      </div>
                      <div
                        *ngIf="!valueSet.type_details.textarea"
                        class="color-grey-900 value"
                        [ngClass]="{ active: selectedValue === valueSet }"
                        (click)="selectDefaultAction()"
                      >
                        <div data-testid="richTextValue" [innerHTML]="valueSet.value | sanitizeHtml"></div>
                        <ng-container *ngTemplateOutlet="hoverOptions; context: { value: valueSet, group: group }">
                        </ng-container>
                      </div>
                      <div *ngIf="valueSet.value === null || !valueSet.value">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </div>
                    </ng-container>

                    <!--file-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.file">
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet.label }}</div>
                      <ul class="pl-4" *ngIf="valueSet.value?.length > 1">
                        <div
                          class="m-0"
                          *ngFor="let fileId of valueSet.value; let isLast = last"
                          [ngClass]="{ 'mb-1': !isLast }"
                        >
                          <li>
                            <button
                              lib-button
                              buttonType="link"
                              [attr.data-testid]="'fileList-' + fileId"
                              *ngIf="documents | getItemById: fileId as docInfo"
                              [innerHTML]="docInfo.name + '.' + docInfo.format | sanitizeHtml"
                              style="z-index: 2"
                              (click)="downloadFile(docInfo, valueSet)"
                            ></button>
                          </li>
                        </div>
                      </ul>
                      <div *ngIf="valueSet.value?.length === 1">
                        <button
                          lib-button
                          buttonType="link"
                          [attr.data-testid]="'file-' + valueSet.value[0]"
                          *ngIf="documents | getItemById: valueSet.value[0] as docInfo"
                          [innerHTML]="docInfo.name + '.' + docInfo.format | sanitizeHtml"
                          style="z-index: 2"
                          (click)="downloadFile(docInfo, valueSet)"
                        ></button>
                      </div>
                      <div *ngIf="valueSet.value === null || !valueSet.value?.length">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </div>
                    </ng-container>

                    <!--file v2-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.file_v2">
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet?.label }}</div>
                      <ng-container *ngIf="valueSet.value === null || !valueSet.value?.length; else fileV2">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </ng-container>
                      <ng-template #fileV2>
                        <lib-metric-view-file-v2
                          [value]="valueSet"
                          [documents]="documents"
                          (downloadFile)="downloadFile($event, valueSet)"
                        ></lib-metric-view-file-v2>
                      </ng-template>
                    </ng-container>

                    <!--tip-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.tip">
                      <ng-container *ngIf="showTip">
                        <lib-data-formats-tip [value]="valueSet"></lib-data-formats-tip>
                      </ng-container>
                    </ng-container>

                    <!--boolean-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.boolean">
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet.label }}</div>
                      <div
                        *ngIf="valueSet.value"
                        class="color-grey-900 value fx-row"
                        (click)="selectDefaultAction()"
                        [ngClass]="{ active: selectedValue === valueSet }"
                      >
                        <div>
                          <span [innerHTML]="getBooleanDisplayValue(valueSet) ?? '' | sanitizeHtml"></span>
                          <ng-container
                            *ngTemplateOutlet="
                              hoverOptions;
                              context: { value: valueSet, singleLine: true, group: group }
                            "
                          >
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="valueSet.value === null">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </div>
                    </ng-container>

                    <!--choice-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.choice">
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet.label }}</div>
                      <div
                        *ngIf="valueSet.value && valueSet.value.values"
                        class="color-grey-900 value"
                        (click)="selectDefaultAction()"
                        [ngClass]="{
                          'fx-row': valueSet.type_details?.multi_choices,
                          active: selectedValue === valueSet,
                        }"
                      >
                        <div>
                          <ng-container *ngIf="valueSet.type_details?.multi_choices; else singleValue">
                            <ul
                              class="m-0 pl-4"
                              *ngFor="let item of valueSet.value.values; let isLast = last"
                              [ngClass]="{ 'pb-1': !isLast }"
                            >
                              <li [innerHTML]="item | sanitizeHtml"></li>
                            </ul>
                          </ng-container>
                          <ng-template #singleValue>
                            <span [innerHTML]="valueSet.value.values?.[0] | sanitizeHtml"></span>
                          </ng-template>
                          <ng-container
                            *ngTemplateOutlet="
                              hoverOptions;
                              context: { value: valueSet, singleLine: true, group: group }
                            "
                          >
                          </ng-container>
                        </div>
                      </div>
                      <div *ngIf="!valueSet.value || !valueSet.value?.values || !valueSet.value?.values?.length">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </div>
                    </ng-container>

                    <!--document link type-->
                    <ng-container *ngSwitchCase="eValueDefinitionType.document">
                      <lib-metric-view-document-link
                        *ngIf="documentContext"
                        [value]="valueSet"
                        [itemType]="documentContext.itemType"
                        [documents]="documents"
                        [documentPayloadOptions]="
                          documentsService.getDocumentPayloadOptions(valueSet, {
                            itemType: documentContext.itemType,
                          })
                        "
                        class="fx-col"
                      ></lib-metric-view-document-link>
                    </ng-container>

                    <!--number type-->
                    <ng-container
                      *ngSwitchCase="
                        [eValueDefinitionType.number, eValueDefinitionType.calculated] | multiSwitchCase: valueSet.type
                      "
                    >
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet.label }}</div>
                      <div
                        *ngIf="valueSet.value"
                        class="color-grey-900 value"
                        (click)="selectDefaultAction()"
                        [ngClass]="{ active: selectedValue === valueSet }"
                      >
                        <span
                          [innerHTML]="
                            valueSet.value
                              | numberMask
                                : valueSet.type_details?.max_decimals
                                : getUnit(valueSet)
                                : true
                                : false
                                : true
                              | sanitizeHtml
                          "
                        ></span>
                        <ng-container
                          *ngTemplateOutlet="hoverOptions; context: { value: valueSet, singleLine: true, group: group }"
                        >
                        </ng-container>
                      </div>
                      <div *ngIf="valueSet.value === null || !valueSet.value?.length">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </div>
                    </ng-container>

                    <!--default-->
                    <ng-container *ngSwitchDefault>
                      <div *ngIf="showLabel" class="mb-1 label">{{ valueSet.label }}</div>
                      <div
                        *ngIf="valueSet.value"
                        class="color-grey-900 value"
                        (click)="selectDefaultAction()"
                        [ngClass]="{ active: selectedValue === valueSet }"
                      >
                        <span
                          [innerHTML]="
                            valueSet.value + ' ' + (valueSet.type_details?.units ? getUnit(valueSet) : '')
                              | sanitizeHtml
                          "
                        ></span>
                        <ng-container
                          *ngTemplateOutlet="hoverOptions; context: { value: valueSet, singleLine: true, group: group }"
                        >
                        </ng-container>
                      </div>
                      <div *ngIf="valueSet.value === null || !valueSet.value?.length">
                        <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>
              <div
                class="overlay"
                [attr.data-testid]="'overlay-' + valueSet.id"
                *ngIf="valueSet.type !== 'tip' && valueSet.type !== 'subtitle' && valueSet.type !== 'label'"
                (click)="selectDefaultAction()"
              >
                <div class="edit" *ngIf="!disableEdit">
                  <mat-icon class="color-primary-600 icon-sm" svgIcon="edit"></mat-icon>
                </div>
              </div>
            </div>
          </ng-container>
          <hr
            *ngIf="!isLastGroup && group.values?.length"
            class="dark w-100"
            [attr.data-testid]="'separator-' + group.value_definition_group_id"
          />
        </ng-container>
        <ng-template #metricTableGroup>
          <h4 class="mat-subtitle-2 mb-2" *ngIf="group | showGroupLabel: sanitizedOrderedValueGroups">
            {{ group.label }}
          </h4>
          <ng-container *ngTemplateOutlet="metricTable; context: { group: group }"></ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #metricTable let-group="group">
  <div class="metric-table-container" *ngIf="group | cellValueDisplay as cellValue">
    <div
      *ngFor="let inputCol of cellValue.inputColumns"
      class="mb-0 mr-1 value-container"
      [ngClass]="{
        'no-action': disableEdit,
        active: isValueDefHighlighted(inputCol, group),
      }"
      (contextmenu)="onContextMenu($event, inputCol, group)"
      (mouseover)="setHoverIndex(inputCol, group)"
      (mouseout)="setHoverIndex()"
    >
      <div *ngIf="showLabel">
        <p class="label">{{ inputCol.label }}</p>
        <ul>
          <li class="label" *ngFor="let contextColValue of cellValue.contextColumns">{{ contextColValue }}</li>
        </ul>
      </div>

      <div
        class="color-grey-900 value"
        (click)="selectDefaultAction()"
        [ngClass]="{ active: selectedValue === inputCol }"
      >
        <p *ngIf="inputCol.value; else cellNoValue">
          <span
            [innerHTML]="
              inputCol.value
                | numberMask: inputCol.type_details?.max_decimals : getUnit(inputCol) : true : false : true
                | sanitizeHtml
            "
          ></span>
          <ng-container *ngTemplateOutlet="hoverOptions; context: { value: inputCol, singleLine: true, group: group }">
          </ng-container>
        </p>
      </div>
      <ng-template #cellNoValue>
        <ng-container *ngTemplateOutlet="noValue; context: { value: inputCol }"></ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #hoverOptions let-valueSet="value" let-singleLine="singleLine" let-group="group">
  <ng-container *ngIf="valueSet?.value && hoverAction">
    <div class="options" [ngClass]="{ 'pl-1': singleLine, multiline: !singleLine, active: selectedValue === valueSet }">
      <div
        class="fx-row copy-icon-container"
        *ngIf="!multiOptions"
        (click)="copyValue(valueSet, $event)"
        data-testid="hover-copy-icon"
      >
        <mat-icon class="icon-sm mr-1 copy-icon" svgIcon="copy"></mat-icon><span>{{ "copy" | translate }}</span>
      </div>
      <div *ngIf="multiOptions" (click)="onContextMenu($event, valueSet, group)" data-testid="value-context-menu">
        <mat-icon class="icon-sm mr-1" svgIcon="more"></mat-icon>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noValue let-valueSet="value">
  <div *ngIf="valueSet?.required === true && valueSet.type !== eValueDefinitionType.calculated" class="color-error">
    {{ "Missing Value" | translate }}
  </div>
  <div *ngIf="valueSet?.required === false || valueSet.type === eValueDefinitionType.calculated">
    {{ "-" | translate }}
  </div>
</ng-template>
